import memoize from "lodash/memoize";
import isBoolean from "lodash/isBoolean";
import { gql } from "@apollo/client";
import client from "../../gqlClient";
import { FrankBackendTypes } from "frank-types";

export interface CachedFormState {
  "createAccount.password"?: string;
  "createAccount.submitted"?: boolean;
  "tellUs.profilePictureUrl"?: string;
  "tellUs.profileName"?: string;
  "tellUs.submitted"?: boolean;
}

// type GetCache = <K extends keyof CachedFormState>(key: K) => CachedFormState[K];
type SetCache = <K extends keyof CachedFormState>(
  key: K,
  val: CachedFormState[K]
) => void;

type HydrateCache = (
  cacheItems: FrankBackendTypes.OnboardingCacheItem[]
) => CachedFormState;

export interface CacheAPI {
  setCache: SetCache;
  hydrateCache: HydrateCache;
}

export default memoize(function makeCache(token: string): CacheAPI {
  async function set<
    K extends keyof CachedFormState
  >(key: K, value: CachedFormState[K]) {
    const isBool = isBoolean(value);

    const input = {
      token,
      key,
      boolValue: isBool ? value : undefined,
      value: isBool ? undefined : value,
    } as FrankBackendTypes.SetOnboardingCacheDto;
    await client.mutate<FrankBackendTypes.MutationSetOnboardingCacheArgs>({
      variables: { input },
      mutation: gql`
        mutation SetOnboardingCache($input: SetOnboardingCacheDTO!) {
          setOnboardingCache(input: $input) {
            nothing
          }
        }
      `,
    });
  }

  function hydrateCache(
    cacheItems: FrankBackendTypes.OnboardingCacheItem[]
  ): CachedFormState {
    return cacheItems.reduce((cache, item) => {
      const hasBool = isBoolean(item.boolValue);
      return { ...cache, [item.key]: hasBool ? item.boolValue : item.value };
    }, {});
  }

  return { setCache: set, hydrateCache };
});
