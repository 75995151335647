import { ScreenSizes, useScreenSize } from "@get-frank-eng/design-system";
import * as React from "react";

export const CoworkerTopBarWrapper: React.FC = ({ children }) => {
  const screensize = useScreenSize();
  const isMobile = screensize < ScreenSizes.MD;

  return (
    <div
      className="fixed top-0 bg-canvas-700 z-20"
      style={{
        width: isMobile ? "100vw" : "calc(100vw - 256px)",
        left: isMobile ? "0" : "256px",
      }}
    >
      {children}
    </div>
  );
};
