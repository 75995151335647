import * as React from "react";

function useIsScrolled<T extends HTMLElement>(ref: React.MutableRefObject<T>) {
  const [isScrolledX, setIsScrolledX] = React.useState(false);
  const [isScrolledY, setIsScrolledY] = React.useState(false);
  const onScroll = React.useCallback(
    e => {
      setIsScrolledX(e.target.scrollLeft > 0);
      setIsScrolledY(e.target.scrollTop > 0);
    },
    [setIsScrolledX, setIsScrolledY]
  );

  React.useEffect(() => {
    const node = ref.current;
    if (ref.current) {
      ref.current.addEventListener("scroll", onScroll);
    }
    return () => {
      if (node) {
        node.removeEventListener("scroll", onScroll);
      }
    };
  });

  return { isScrolledX, isScrolledY };
}
export default useIsScrolled;
