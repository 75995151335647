import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const RETAKE_TOUR_MUTATION = gql`
  mutation RetakeTour($tour: TourType!) {
    retakeTour(tour: $tour) {
      nothing
    }
  }
`;

export default function useRetakeTour() {
  const [invokeRetakeTour] = useMutation<
    Pick<FrankBackendTypes.Mutation, "retakeTour">,
    FrankBackendTypes.MutationRetakeTourArgs
  >(RETAKE_TOUR_MUTATION);

  const retakeTour = React.useCallback(
    (tour) => {
      return invokeRetakeTour({
        variables: {
          tour,
        },
      });
    },
    [invokeRetakeTour]
  );

  return { retakeTour };
}
