import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import attachmentFragment from "../../../Attachment/dataAccess/attachmentFragment";

const QUERY = gql`
  query Campaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id
      title
      workflowState
      signatureGoal
      signatureCount
      signaturesVisible
      relationshipToMe
      categories
      channelId
      publishedAt
      collaboratorInvite {
        id
        inviter {
          id
          shortName
          profilePic {
            id
            url
          }
        }
      }

      signatures(pagination: { page: 0, perPage: 20 }) {
        total
      }

      campaignOrganizers {
        id
        name
        shortName
        profilePic {
          id
          url
        }
      }
      links {
        id
        preview {
          title
          subtitle
          imageUrl
          url
        }
      }
      attachments(pagination: { page: 0, perPage: 20 }) {
        total
        hasNext
        objects {
          ...AttachmentFragment
        }
      }
      actions {
        id
        action
        yourVote
        upvotes
        downvotes
        title
        description
      }
      contentJSON
      contentPreview
      petitionRecipients {
        email
        fullName
        id
      }
    }
  }
  ${attachmentFragment}
`;

export default function useCampaignDetailQuery({
  campaignId,
}: {
  campaignId: string;
}) {
  const { data, loading, error, refetch } = useQuery<{
    campaign: FrankBackendTypes.Campaign;
  }>(QUERY, {
    variables: { campaignId },
  });

  return {
    campaign: data?.campaign,
    loadingCampaign: !!data?.campaign && loading,
    errorCampaign: error,
    refetchCampaign: refetch,
  };
}
