import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import createPersistedState from "use-persisted-state";

const useGroupsLocalStorageState = createPersistedState("groupId");

const ADD_COLLABORATORS = gql`
  mutation addCollaborators($input: AddCollaboratorsDTO!) {
    addCollaborators(input: $input) {
      nothing
    }
  }
`;

export default function useInviteCollaborators(campaignId: string) {
  const [groupId] = useGroupsLocalStorageState<string | null>(null);
  const [invokeAddCollaborators, { called, loading }] = useMutation<
    any,
    { input: FrankBackendTypes.AddCollaboratorsDto }
  >(ADD_COLLABORATORS);

  const inviteCollaborators = React.useCallback(
    (
      args: Omit<
        FrankBackendTypes.AddCollaboratorsDto,
        "groupId" | "campaignId"
      >
    ) =>
      invokeAddCollaborators({
        variables: { input: { ...args, groupId, campaignId } },
      }),
    [invokeAddCollaborators, groupId, campaignId]
  );

  return { inviteCollaborators, loading, called };
}
