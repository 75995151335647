import { FrankBackendTypes } from "frank-types";

export const joinedAtDesc = {
  column: FrankBackendTypes.CoworkerOrderColumns.JoinedAt,
  order: FrankBackendTypes.Order.Desc,
};

export const onboardingStartingAtAsc = {
  column: FrankBackendTypes.CoworkerOrderColumns.OnboardingStartedAt,
  order: FrankBackendTypes.Order.Asc,
};

export const totalTrustDownvotesAsc = {
  column: FrankBackendTypes.CoworkerOrderColumns.TotalTrustDownvotes,
  order: FrankBackendTypes.Order.Asc,
};

export const defaultPendingMemberOrder = {
  order: totalTrustDownvotesAsc,
  secondOrder: onboardingStartingAtAsc,
};

export const trustedAtDesc = {
  column: FrankBackendTypes.CoworkerOrderColumns.YourTrustDate,
  order: FrankBackendTypes.Order.Desc,
};
