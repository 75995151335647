import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import { CampaignUserRelationship } from "frank-types/dist/frank-backend-types";
import groupBy from "lodash/groupBy";
import * as React from "react";
import { resourceFragment } from "../../../Resources/dataAccess/useResourcesData";

export function useCampaignsByRelationship(
  campaigns: FrankBackendTypes.Campaign[] | null
) {
  const campaignsByRelationship: CampaignsByCategory = React.useMemo(() => {
    if (!campaigns) {
      return null;
    }
    return {
      [FrankBackendTypes.CampaignUserRelationship.Organizer]: [],
      [FrankBackendTypes.CampaignUserRelationship.InvitedSupporter]: [],
      [FrankBackendTypes.CampaignUserRelationship.InvitedNotSupporter]: [],
      [FrankBackendTypes.CampaignUserRelationship.Supporter]: [],
      [FrankBackendTypes.CampaignUserRelationship.None]: [],
      ...groupBy(campaigns, "relationshipToMe"),
    } as CampaignsByCategory;
  }, [campaigns]);

  return campaignsByRelationship;
}

export const campaignsFragment = gql`
  fragment CampaignsFragment on Campaign {
    id
    title
    categories
    workflowState
    publishingValidationIssueCount
    relationshipToMe
    signatureGoal
    signatureCount
    campaignOrganizers {
      id
      shortName
      profilePic {
        id
        url
      }
    }
  }
`;

const QUERY = gql`
  query(
    $relationshipToMe: CampaignUserRelationship
    $workflowState: CampaignWorkflowState
  ) {
    resources(tag: "campaigns") {
      ...ResourceFragment
    }
    campaigns(
      relationshipToMe: $relationshipToMe
      workflowState: $workflowState
    ) {
      ...CampaignsFragment
    }
  }
  ${resourceFragment}
  ${campaignsFragment}
`;

type CampaignsByCategory = {
  [key in FrankBackendTypes.CampaignUserRelationship]: FrankBackendTypes.Campaign[];
};

export default function useCampaignList({
  workflowState,
  relationshipToMe,
}: {
  relationshipToMe?: CampaignUserRelationship;
  workflowState?: FrankBackendTypes.CampaignWorkflowState;
}) {
  const { data, error, loading, refetch } = useQuery<
    {
      campaigns: FrankBackendTypes.Campaign[];
      resources: FrankBackendTypes.Resource[];
    },
    {
      relationshipToMe?: CampaignUserRelationship;
      workflowState?: FrankBackendTypes.CampaignWorkflowState;
    }
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: { workflowState, relationshipToMe },
  });

  const campaignsByRelationship = useCampaignsByRelationship(data?.campaigns);

  return {
    campaigns: data?.campaigns,
    resources: data?.resources,
    campaignsByRelationship,
    error,
    loading,
    refetch,
  };
}
