export function capitalize(str: string): string {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export function titleize(str: string): string {
  return str
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
}
