import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const ADD = gql`
  mutation AddRecipient($input: AddPetitionRecipientDTO!) {
    addPetitionRecipient(input: $input) {
      id
      fullName
      email
    }
  }
`;

const REMOVE = gql`
  mutation RemoveRecipient($recipientId: ID!) {
    removePetitionRecipient(recipientId: $recipientId) {
      nothing
    }
  }
`;

export default function usePetitionRecipients({
  campaignId,
}: {
  campaignId: string;
}) {
  const [invokeAdd, { loading, error }] = useMutation<
    any,
    FrankBackendTypes.MutationAddPetitionRecipientArgs
  >(ADD);

  const [
    invokeRemove,
    { loading: loadingRemove, error: errorRemove },
  ] = useMutation<any, FrankBackendTypes.MutationRemovePetitionRecipientArgs>(
    REMOVE
  );

  const removeRecipient = React.useCallback(
    async (recipientId: string) => {
      await invokeRemove({ variables: { recipientId } });
    },
    [invokeRemove]
  );

  const addRecipient = React.useCallback(
    async ({ fullName, email }: { fullName: string; email: string }) => {
      await invokeAdd({
        variables: {
          input: {
            fullName,
            email,
            campaignId,
          },
        },
      });
    },
    [campaignId, invokeAdd]
  );

  return {
    addRecipient,
    loadingAddRecipient: loading,
    errorAddRecipient: error,
    removeRecipient,
    loadingRemove,
    errorRemove,
  };
}
