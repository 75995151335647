import * as React from "react";

export default () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.7729 8.66634H9.10628H7.77295H5.10628V7.33301H7.77295H9.10628H11.7729V8.66634ZM8.43962 1.33301C7.56414 1.33301 6.69723 1.50545 5.88839 1.84048C5.07956 2.17551 4.34463 2.66657 3.72557 3.28563C2.47533 4.53587 1.77295 6.23156 1.77295 7.99967C1.77295 9.76779 2.47533 11.4635 3.72557 12.7137C4.34463 13.3328 5.07956 13.8238 5.88839 14.1589C6.69723 14.4939 7.56414 14.6663 8.43962 14.6663C10.2077 14.6663 11.9034 13.964 13.1537 12.7137C14.4039 11.4635 15.1063 9.76779 15.1063 7.99967C15.1063 7.1242 14.9338 6.25729 14.5988 5.44845C14.2638 4.63961 13.7727 3.90469 13.1537 3.28563C12.5346 2.66657 11.7997 2.17551 10.9908 1.84048C10.182 1.50545 9.3151 1.33301 8.43962 1.33301Z" />
  </svg>
);
