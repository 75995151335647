import { useQuery, gql } from "@apollo/client";
import topicFragment from "../topicFragment";
import { FrankBackendTypes } from "frank-types";
import { PaginationArgumentDto } from "frank-types/dist/frank-backend-types";
import { resourceFragment } from "../../../Resources/dataAccess/useResourcesData";

const query = gql`
  query Topic($slug: String!, $attachmentPagination: PaginationArgumentDTO!) {
    topic(slug: $slug) {
      ...TopicFragment
      attachments(pagination: $attachmentPagination) {
        total
      }
      resources {
        ...ResourceFragment
      }
    }
  }
  ${topicFragment}
  ${resourceFragment}
`;

export default function useTopic(slug: string) {
  const { data, error, loading, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "topic">,
    FrankBackendTypes.QueryTopicArgs & {
      attachmentPagination: PaginationArgumentDto;
    }
  >(query, {
    variables: {
      slug,
      attachmentPagination: { perPage: 10, page: 0 },
    },
  });

  return {
    loading,
    refetch,
    error,
    topic: data?.topic,
  };
}
