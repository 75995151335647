/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Checkbox } from "@get-frank-eng/design-system";

const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  { indeterminate: boolean }
>(
  (
    { indeterminate, ...rest },
    ref: React.MutableRefObject<HTMLInputElement>
  ) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={ref} {...rest} />
      </>
    );
  }
);

export default IndeterminateCheckbox;
