import { Delay, PulseMessage } from "@get-frank-eng/design-system";
import * as React from "react";

export default function MessagesLoading() {
  return (
    <Delay delay={100}>
      <div className="space-y-6 flex-1 max-w-5xl w-full px-6 pb-4">
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
        <PulseMessage />
      </div>
    </Delay>
  );
}
