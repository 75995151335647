import * as React from "react";
import { ScreenSizes, useResponsive } from "@get-frank-eng/design-system";

const ContentContainer = ({ children }) => {
  const { screenSize } = useResponsive();
  return (
    <div
      style={{ width: screenSize <= ScreenSizes.SM ? "100%" : "528px" }}
      className="mx-auto mt-8 md:mt-24 pb-48 md:pb-0 md:pt-1"
    >
      {children}
    </div>
  );
};

export default ContentContainer;
