import { ChatTypes } from "frank-types";
import * as React from "react";
import { getCurrentChatUser } from "../core/currentChatUser";

const currentChatUserContext = React.createContext<{
  currentChatUser: ChatTypes.User | null;
  currentAnonUser: ChatTypes.User | null;
}>({ currentAnonUser: null, currentChatUser: null });

export const CurrentChatUserProvider: React.FC = ({ children }) => {
  const [
    currentChatUser,
    setCurrentChatUser,
  ] = React.useState<ChatTypes.User | null>(null);
  const [
    currentAnonUser,
    setCurrentAnonUser,
  ] = React.useState<ChatTypes.User | null>(null);

  React.useEffect(() => {
    getCurrentChatUser().then((data) => {
      setCurrentChatUser(data.whoami);
      setCurrentAnonUser(data.whoamiAnonymous);
    });
  }, [setCurrentAnonUser, setCurrentChatUser]);

  return (
    <currentChatUserContext.Provider
      value={{ currentChatUser, currentAnonUser }}
    >
      {children}
    </currentChatUserContext.Provider>
  );
};

export function useCurrentChatUser() {
  const { currentChatUser, currentAnonUser } = React.useContext(
    currentChatUserContext
  );

  return { currentChatUser, currentAnonUser };
}
