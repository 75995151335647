import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const OPEN_MUTATION = gql`
  mutation OpenSection($section: CloseableSectionNames!) {
    openNavSection(section: $section) {
      nothing
    }
  }
`;

const CLOSE_MUTATION = gql`
  mutation CloseSection($section: CloseableSectionNames!) {
    closeNavSection(section: $section) {
      nothing
    }
  }
`;

export default function useSidebarSectionOpen() {
  const [invokeOpen] = useMutation<
    any,
    FrankBackendTypes.MutationOpenNavSectionArgs
  >(OPEN_MUTATION);
  const [invokeClose] = useMutation<
    any,
    FrankBackendTypes.MutationCloseNavSectionArgs
  >(CLOSE_MUTATION);

  const open = React.useCallback(
    (section: FrankBackendTypes.CloseableSectionNames) => {
      return invokeOpen({ variables: { section } });
    },
    [invokeOpen]
  );

  const close = React.useCallback(
    (section: FrankBackendTypes.CloseableSectionNames) => {
      return invokeClose({ variables: { section } });
    },
    [invokeClose]
  );

  return { open, close };
}
