import * as React from "react";
import useQueryParams from "../../hooks/useQueryParams";

export default function useEmphasizedMessage() {
  const query = useQueryParams();

  const startOfPage = query.get("startOfPage")
    ? new Date(query.get("startOfPage"))
    : null;

  const [emphasizedMessageId, setEmphasizedMessageId] = React.useState(
    query.get("messageId")
  );

  const clearEmphasizedMessage = React.useCallback(() => {
    setEmphasizedMessageId(null);
  }, [setEmphasizedMessageId]);

  React.useEffect(() => {
    document.body.addEventListener("click", clearEmphasizedMessage);
    return () =>
      document.body.removeEventListener("click", clearEmphasizedMessage);
  }, [clearEmphasizedMessage]);

  return { startOfPage, emphasizedMessageId };
}
