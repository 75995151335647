import { PreviewCard } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const AttachmentsAndLinks = ({
  links,
  attachments,
}: {
  links: FrankBackendTypes.Link[];
  attachments: FrankBackendTypes.Attachment[];
}) => (
  <div className="grid gap-6 grid-cols-4">
    {links.map((link) => (
      // ignore because we got: Expression produces a union type that is too complex to represent.
      // @ts-ignore
      <PreviewCard {...link.preview} icon="link" />
    ))}

    {attachments.map((attachment) => (
      // ignore because we got: Expression produces a union type that is too complex to represent.
      // @ts-ignore
      <PreviewCard {...attachment.preview} icon="attachment" />
    ))}
  </div>
);

export default AttachmentsAndLinks;
