import * as React from "react";

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.3335 8.66634H8.66683V11.333H7.3335V8.66634H4.66683V7.33301H7.3335V4.66634H8.66683V7.33301H11.3335V8.66634ZM8.00016 1.33301C7.12468 1.33301 6.25778 1.50545 5.44894 1.84048C4.6401 2.17551 3.90517 2.66657 3.28612 3.28563C2.03588 4.53587 1.3335 6.23156 1.3335 7.99967C1.3335 9.76779 2.03588 11.4635 3.28612 12.7137C3.90517 13.3328 4.6401 13.8238 5.44894 14.1589C6.25778 14.4939 7.12468 14.6663 8.00016 14.6663C9.76827 14.6663 11.464 13.964 12.7142 12.7137C13.9645 11.4635 14.6668 9.76779 14.6668 7.99967C14.6668 7.1242 14.4944 6.25729 14.1594 5.44845C13.8243 4.63961 13.3333 3.90469 12.7142 3.28563C12.0952 2.66657 11.3602 2.17551 10.5514 1.84048C9.74255 1.50545 8.87564 1.33301 8.00016 1.33301Z" />
  </svg>
);
