import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const RESOLVE_COMMENT = gql`
  mutation ResolveComment($campaignId: ID!, $commentId: ID!) {
    resolveCampaignComment(campaignId: $campaignId, commentId: $commentId) {
      nothing
    }
  }
`;

export default function useResolveComment() {
  const [invokeResolveComment, { loading }] = useMutation<
    any,
    FrankBackendTypes.MutationResolveCampaignCommentArgs
  >(RESOLVE_COMMENT);

  return { invokeResolveComment, loading };
}
