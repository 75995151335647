import { gql } from "@apollo/client";

export const trustedByFragment = gql`
  fragment TrustedByFragment on Coworker {
    trustedBy {
      id
      shortName
      name
      isYou
    }
  }
`;

export const distrustedByFragment = gql`
  fragment DistrustedByFragment on Coworker {
    distrustedBy {
      id
      shortName
      name
      isYou
    }
  }
`;

export const userFragment = gql`
  fragment UserFragment on Coworker {
    user {
      id
      shortName
      name
      createdAt
      profilePic {
        id
        url
      }
    }
  }
`;
