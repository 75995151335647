import * as React from "react";

export default () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15"
      cy="15"
      r="13.5"
      fill="#2A877C"
      stroke="white"
      strokeWidth="3"
    />
    <circle
      cx="15"
      cy="15"
      r="13"
      fill="#1D706B"
      stroke="white"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.79333 9.82374L14.46 7.7504C14.8 7.59707 15.2 7.59707 15.54 7.7504L20.2067 9.82374C20.6867 10.0371 21 10.5171 21 11.0437V14.1771C21 17.8771 18.44 21.3371 15 22.1771C11.56 21.3371 9 17.8771 9 14.1771V11.0437C9 10.5171 9.31333 10.0371 9.79333 9.82374ZM11.4666 15.9771L13.1933 17.7037C13.4533 17.9637 13.88 17.9637 14.1333 17.7037L18.5266 13.3104C18.7866 13.0504 18.7866 12.6304 18.5266 12.3704C18.2666 12.1104 17.8466 12.1104 17.5866 12.3704L13.6666 16.2904L12.4066 15.0371C12.1466 14.7771 11.7266 14.7771 11.4666 15.0371C11.3418 15.1616 11.2716 15.3307 11.2716 15.5071C11.2716 15.6834 11.3418 15.8525 11.4666 15.9771Z"
      fill="white"
    />
  </svg>
);
