import * as React from "react";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import DocumentTitle from "react-document-title";

interface Props {
  direction?: "left" | "right";
  top?: React.ReactNode;
  side?: React.ReactNode;
  children?: React.ReactNode;
  title?: string;
  showBottomBorderOfTop?: boolean;
  grow?: boolean;
  header?: React.ReactNode;
}

const topClasses = (showBottomBorderOfTop) => [
  "fixed",
  "top-0",
  "left-0",
  "md:px-6",
  "w-screen",
  "h-16",
  "items-center",
  "flex",
  "flex-row",
  "justify-between",

  {
    "border-b": showBottomBorderOfTop,
  },
  "border-canvas-200",
  "z-10",
];

const containerClasses = [
  "mb-12",
  "px-6",
  "mx-auto",
  "flex-grow",
  "max-w-5xl",
  "w-full",
  "flex",
  "flex-col",
];

const sideClasses = (direction: "left" | "right") => [
  "flex-shrink-0",
  "md:w-88",
  "flex-shrink-0",
  { "lg:ml-4": direction === "right" },
  { "lg:mr-4": direction === "left" },
];

const mainContentClasses = (grow) =>
  classNames([
    // "py-6",
    {
      "flex-grow": grow,
    },
  ]);

const DetailLayout = ({
  title,
  direction,
  top,
  side,
  children,
  showBottomBorderOfTop = true,
  grow = true,
  header,
}: Props) => {
  const topContent = top && (
    <motion.div
      initial={{ opacity: 0, pointerEvents: "none" }}
      animate={{ opacity: 1, pointerEvents: "auto" }}
      exit={{ opacity: 0, pointerEvents: "none" }}
      className={classNames(topClasses(showBottomBorderOfTop))}
    >
      {top}
    </motion.div>
  );

  const sideContent = side && (
    <div className={classNames(sideClasses(direction))}>{side}</div>
  );

  const mainContent = title ? (
    <DocumentTitle title={title}>
      <>{children}</>
    </DocumentTitle>
  ) : (
    children
  );

  return (
    <>
      <AnimatePresence>{topContent}</AnimatePresence>
      <div className={classNames(containerClasses)}>
        {!!header && header}
        <div className="flex flex-col md:flex-row sm:space-x-8 my-6 pb-32 sm:pb-12">
          {direction === "left" && !!side && sideContent}
          <div className={classNames(mainContentClasses(grow))}>
            {mainContent}
          </div>
          {direction === "right" && !!side && sideContent}
        </div>
      </div>
    </>
  );
};

export default DetailLayout;
