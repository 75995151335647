/**
 * Modified from https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */

import * as React from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useClickOutside<RefType extends HTMLElement>({
  ref,
  onClickOutside
}: {
  ref: React.RefObject<RefType>;
  onClickOutside: () => any;
}) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
