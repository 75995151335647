import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { useHistory } from "react-router";
import * as analytics from "../../../analytics";
import { onboardingFragment } from "../queries/useOnboardingQuery";
import useIntercom from "../../../useIntercom";
import { FrankBackendTypes } from "frank-types";
import SentryClient from "../../../sentryClient";

const START_ONBOARDING = gql`
  mutation StartOnboarding($token: String!) {
    startOnboarding(token: $token) {
      ...OnboardingFragment
    }
  }
  ${onboardingFragment}
`;

function getTokenFromURL() {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const token = queryParams.get("token");
  return token;
}

export default function useStartOnboarding() {
  const token = getTokenFromURL();

  const history = useHistory();

  const [
    onboardingData,
    setOnboardingData,
  ] = React.useState<null | FrankBackendTypes.Onboarding>(null);
  const [onboardingError, setOnboardingError] = React.useState(null);

  const [invokeStartOnboarding, { called, loading }] = useMutation<
    { startOnboarding: FrankBackendTypes.Onboarding },
    FrankBackendTypes.MutationStartOnboardingArgs
  >(START_ONBOARDING, {
    variables: { token },
  });

  useIntercom({
    userId: onboardingData?.userId,
    intercomHash: onboardingData?.intercomHash,
    email: onboardingData?.email,
    groupId: onboardingData?.groupId,
    groupName: onboardingData?.group.name,
  });

  const runMutation = React.useCallback(async () => {
    try {
      const res = await invokeStartOnboarding({
        variables: {
          token,
        },
      });

      setOnboardingData(res.data.startOnboarding);
      if (res.errors) {
        setOnboardingError(res.errors);
      }
    } catch (e) {
      setOnboardingError(e);
    }
  }, [invokeStartOnboarding, token]);

  React.useEffect(() => {
    runMutation();
  }, []);

  React.useEffect(() => {
    if (!onboardingData) {
      return;
    }
    analytics.init({
      history,
      userId: onboardingData.userId,
    });

    if (onboardingData.intercomHash) {
      SentryClient.configureScope(function (scope) {
        scope.setTag("onboarding-type", "worker-two");
        scope.setTag("group", onboardingData?.groupId);
        scope.setUser({ id: onboardingData?.userId, workerType: "worker-two" });
      });
    }
  }, [onboardingData, history]);
  return {
    onboardingLoading: !called && loading,
    onboardingError,
    onboardingData,
    token,
    onboardingRefetch: runMutation,
  };
}
