/* eslint-disable no-param-reassign */
import { v4 as uuid } from "uuid";
import { Transforms, Editor, Range, Element } from "slate";

export const withComments = <T extends Editor>(editor: T) => {
  const e = editor as T;

  const { isInline } = e;

  e.isInline = (element: Element) => {
    return element.type === "comment" ? true : isInline(element);
  };

  return editor;
};

export const isCommentActive = (editor: Editor) => {
  const [comment] = Editor.nodes(editor, { match: n => n.type === "comment" });
  return !!comment;
};

export const unwrapComment = (editor: Editor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === "comment" });
};

export const wrapComment = (
  editor: Editor,
  commentId: string = uuid(),
  range?: Range
) => {
  if (!range) {
    range = editor.selection;
  }
  if (isCommentActive(editor)) {
    unwrapComment(editor);
  }

  const isCollapsed = range && Range.isCollapsed(range);
  if (isCollapsed) {
    return;
  }
  const comment = {
    type: "comment",
    commentId,
    children: []
  };

  Transforms.wrapNodes(editor, comment, { split: true, at: range });
  Transforms.collapse(editor, { edge: "end" });
};

export const insertComment = (
  editor: Editor,
  commentId: string,
  range?: Range
) => {
  if (!range) {
    range = editor.selection;
  }
  wrapComment(editor, commentId, range);
};
