import { gql } from "@apollo/client";
import { getClient } from "../../gql-client";
import { ChatTypes } from "frank-types";

export async function sendMessage(
  input: ChatTypes.SendMessageInput
): Promise<ChatTypes.Message> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "sendMessage">,
    ChatTypes.MutationSendMessageArgs
  >({
    mutation: gql`
      mutation SendMessage($input: SendMessageInput!) {
        sendMessage(input: $input) {
          id
        }
      }
    `,
    variables: { input: { ...input } },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.sendMessage;
}

export async function editMessage(
  input: ChatTypes.EditMessageInput
): Promise<ChatTypes.Message> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "editMessage">,
    ChatTypes.MutationEditMessageArgs
  >({
    mutation: gql`
      mutation EditMessage($input: EditMessageInput!) {
        editMessage(input: $input) {
          id
        }
      }
    `,
    variables: { input },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.editMessage;
}

export async function deleteMessage(
  input: ChatTypes.DeleteMessageInput
): Promise<void> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "deleteMessage">,
    ChatTypes.MutationDeleteMessageArgs
  >({
    mutation: gql`
      mutation EditMessage($input: DeleteMessageInput!) {
        deleteMessage(input: $input) {
          nothing
        }
      }
    `,
    variables: { input },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }
}

export async function flagMessage(
  input: ChatTypes.FlagMessageInput
): Promise<void> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "flagMessage">,
    ChatTypes.MutationFlagMessageArgs
  >({
    mutation: gql`
      mutation FlagMessage($input: MutationFlagMessageArgs!) {
        flagMessage(input: $input) {
          nothing
        }
      }
    `,
    variables: { input },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }
}

export async function reactToMessage(
  input: ChatTypes.ReactToMessageInput
): Promise<void> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "reactToMessage">,
    ChatTypes.MutationReactToMessageArgs
  >({
    mutation: gql`
      mutation ReactToMessage($input: ReactToMessageInput!) {
        reactToMessage(input: $input) {
          nothing
        }
      }
    `,
    variables: { input },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }
}
