import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: CreateCampaignDTO!) {
    createCampaign(input: $input) {
      id
    }
  }
`;

export default function useCreateCampaign() {
  const [
    invokeCreateCampaign,
    {
      data: createCampaignData,
      called: createCampaignCalled,
      loading: createCampaignLoading,
      error: createCampainError,
    },
  ] = useMutation<
    { createCampaign: FrankBackendTypes.Campaign },
    FrankBackendTypes.MutationCreateCampaignArgs
  >(CREATE_CAMPAIGN);

  const createCampaign = React.useCallback(async () => {
    await invokeCreateCampaign({ variables: { input: {} } });
  }, [invokeCreateCampaign]);

  return {
    createCampaign,
    createCampaignData,
    createCampaignCalled,
    createCampaignLoading,
    createCampainError,
  };
}
