import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

export const resourceFragment = gql`
  fragment ResourceFragment on Resource {
    url
    title
    label
    creator
    logoUrl
    imageUrl
  }
`;

const QUERY = gql`
  query($tag: String!) {
    resources(tag: $tag) {
      ...ResourceFragment
    }
  }
  ${resourceFragment}
`;

export default function useResourcesData(tag: string) {
  const { data, error, loading, refetch } = useQuery<
    {
      resources: FrankBackendTypes.Resource[];
    },
    FrankBackendTypes.QueryResourcesArgs
  >(QUERY, { variables: { tag } });

  return {
    data: data?.resources,
    error,
    loading,
    refetch,
  };
}
