import * as React from "react";
import classNames from "classnames";

const sizeClasses = {
  large: "md:grid-cols-2",
  small: "grid-cols-2 md:grid-cols-4",
  medium: "grid-cols-2 md:grid-cols-2 lg:grid-cols-3",
  xl: "md:grid-cols-2",
};

const CardGrid = ({ children, size = "large" }) => {
  return (
    <div
      className={classNames([
        "grid grid-cols-1 gap-x-2 gap-y-3",
        sizeClasses[size],
      ])}
    >
      {children}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { CardGrid };
