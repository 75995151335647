import { FrankBackendTypes } from "frank-types";
import find from "lodash/find";

export const transformRawColumnData = (
  columns: FrankBackendTypes.CoworkerCustomColumn[]
) => {
  return columns?.map((c) => ({
    Header: c.name,
    accessor: (row) => {
      const datum = find(row.customData, { key: c.name });
      return datum?.value;
    },
    editable: true,
    isAlwaysInteractable: true,
    ...c,
  }));
};
