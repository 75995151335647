import * as React from "react";
import { Editor, Range } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import CommentForm from "./CommentForm";
import { IconButton, Portal } from "./Components";
import LinkForm from "./LinkForm";
import { isBlockActive, toggleBlock } from "./plugins/block";
import { isCommentActive } from "./plugins/comment";
import { isLinkActive } from "./plugins/link";
import { isMarkActive, toggleMark } from "./plugins/mark";

const MarkButton: React.FC<any> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <IconButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <i className="material-icons-outlined">{icon}</i>
    </IconButton>
  );
};

const BlockButton: React.FC<any> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <IconButton
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <i className="material-icons-outlined">{icon}</i>
    </IconButton>
  );
};

const CommentButton = ({ setFollowUp }) => {
  const editor = useSlate();

  const isActive = isCommentActive(editor);
  return (
    <IconButton
      active={isActive}
      onMouseDown={(event) => {
        event.preventDefault();
        // return wrapComment(editor, undefined, lastRange);
        setFollowUp("comment");
      }}
    >
      <i className="material-icons-outlined">comment</i>
    </IconButton>
  );
};

const LinkButton = ({ setFollowUp }) => {
  const editor = useSlate();

  const isActive = isLinkActive(editor);

  return (
    <IconButton
      active={isActive}
      onMouseDown={(event) => {
        event.preventDefault();
        return setFollowUp("link");
      }}
    >
      <i className="material-icons-outlined">link</i>
    </IconButton>
  );
};

function applyDimensionsToElem(el: HTMLDivElement, domSelection: Selection) {
  const domRange = domSelection.getRangeAt(0);

  const rect = domRange.getBoundingClientRect();
  el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
  el.style.left = `${
    rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
  }px`;
}

export default ({ docId, onComment }) => {
  const [followUp, setFollowUp] = React.useState<"link" | null>(null);
  const ref = React.useRef<HTMLDivElement>();
  const [lastDomSelection, setLastDomSelection] = React.useState<Selection>();
  const editor = useSlate();
  const [lastEditorRange, setLastEditorRange] = React.useState<Range | null>(
    editor.selection
  );
  React.useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (!selection) {
      return;
    }

    setLastEditorRange(selection);

    if (
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      el.classList.add("opacity-0");
      return;
    }

    if (followUp) {
      return;
    }

    el.classList.remove("opacity-0");
    const domSelection = window.getSelection();
    applyDimensionsToElem(el, domSelection);
    setLastDomSelection(domSelection);
  });

  if (followUp === "link") {
    return (
      <LinkForm
        linkRange={lastEditorRange}
        setFollowUp={setFollowUp}
        lastDomSelection={lastDomSelection}
      />
    );
  }

  if (followUp === "comment") {
    return (
      <CommentForm
        commentRange={lastEditorRange}
        setFollowUp={setFollowUp}
        lastDomSelection={lastDomSelection}
        docId={docId}
        onComment={onComment}
      />
    );
  }

  return (
    <Portal>
      <div
        ref={ref}
        className="transform -translate-y-2 border absolute opacity-0 transition-opacity duration-200 z-50 shadow-xs-and-l rounded bg-canvas-600 p-2 flex flex-row items-center"
      >
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />

        <BlockButton format="heading-two" icon="title" />
        <BlockButton format="block-quote" icon="format_quote" />

        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        {/* <BlockButton format="comment" icon="comment" /> */}

        <CommentButton setFollowUp={setFollowUp} />

        <LinkButton setFollowUp={setFollowUp} />
      </div>
    </Portal>
  );
};
