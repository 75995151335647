import * as React from "react";

export const StepLabel: React.FC = ({ children }) => {
  return <div className="t-mini plus">{children}</div>;
};

export const StepTitle: React.FC = ({ children }) => {
  return <h1 className="t-title-4 plus">{children}</h1>;
};

export const StepDescription: React.FC = ({ children }) => {
  return <p className="t-regular text-canvas-400">{children}</p>;
};

export const StepContainer: React.FC = ({ children }) => {
  return (
    <div className="max-w-xl mx-auto mt-12 md:mt-24 px-4 sm:px-0">
      {children}
      <div className=" h-48 sm:h-4" />
    </div>
  );
};
