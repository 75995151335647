import * as React from "react";
import Frank from "../LogoFull";

const defaultTitle = "Link no longer active";
const defaultIcon = "link_off";
const defaultText = (
  <>
    You will need a new or updated invitation in order to join Frank. We suggest
    privately messaging a coworker that you trust to request an invitation.
  </>
);

const OnboardingFail = ({
  icon = defaultIcon,
  title = defaultTitle,
  text = defaultText,
  rotate = true,
}: {
  icon?: string;
  title?: string;
  text?: React.ReactElement;
  rotate?: boolean;
}) => {
  return (
    <div className="mx-4 sm:mx-0 w-screen">
      <a
        href="https://getfrank.com/"
        target="_blank"
        rel="noreferrer"
        className="hidden sm:block absolute ml-12 mt-6"
      >
        <Frank />
      </a>
      <div className="flex flex-row items-center justify-center pt-32 sm:pt-0 sm:h-screen md:pb-32 bg-canvas-800">
        <div className="w-100 flex-1 flex flex-col self-center container mx-auto max-w-xl">
          <div className="flex flex-col items-center justify-center text-center">
            <div>
              <i
                className={`material-icons-outlined transform ${
                  rotate ? " -rotate-45" : ""
                }`}
                style={{ fontSize: "40px" }}
              >
                {icon}
              </i>
            </div>
            <h2 className="py-2 t-title-4 plus">{title}</h2>
            <p className="pb-6 t-regular text-canvas-400">{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingFail;
