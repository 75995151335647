"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderChatMentions = void 0;
function renderChatMentions(currentUser, href) {
    return (mention) => {
        const url = href(mention);
        if (mention.type === "coworker" && mention.id === currentUser.id) {
            return `<a key=${mention.id} href="${url}" class="bg-brand-800 cursor-pointer rounded-sm mr-1 t-small plus focus:outline-transparent pl-0.5">
            ${mention.display}
          </a>`;
        }
        if (mention.type === "coworker" && mention.id !== currentUser.id) {
            return `<a key=${mention.id} href="${url}"  class="bg-frank-blue-800 cursor-pointer rounded-sm mr-1 t-small plus focus:outline-transparent pl-0.5">
            <span style="color:#87A9CE">${mention.display}</span>
          </a>`;
        }
        if (mention.type === "channel" || mention.type === "topic") {
            return `<a
            key=${mention.id} 
            href="${url}" 
            class="bg-frank-blue-800 cursor-pointer rounded-sm mr-1 t-small plus focus:outline-transparent pl-0.5"
          >
          <span style="color:#87A9CE">${mention.display}</span>
          </a>`;
        }
        return mention.display;
    };
}
exports.renderChatMentions = renderChatMentions;
