import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const CAMPAIGN_QUERY = gql`
  query Campaigns($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id
      title
      workflowState
      collaborators {
        id
        workflowState
        coworker {
          id
          name
          user {
            shortName
            id
            profilePic {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export default function useCampaignData(campaignId: string) {
  const { data, loading, error, refetch } = useQuery<
    { campaign: FrankBackendTypes.Campaign },
    { campaignId: string }
  >(CAMPAIGN_QUERY, { variables: { campaignId } });

  return {
    campaignData: data?.campaign,
    campaignLoading: loading,
    campaignError: error,
    campaignRefetch: refetch,
  };
}
