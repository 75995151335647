import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const VOTE_ON_ACTION = gql`
  mutation AddVote($input: VoteOnActionDTO!) {
    addVote(input: $input) {
      id
      action
      yourVote
      upvotes
      downvotes
      title
      description
    }
  }
`;

export default function useVoteOnAction({
  campaignId,
  afterVote,
}: {
  campaignId: FrankBackendTypes.Campaign["id"];
  afterVote?: () => any;
}) {
  const [invokeAddVote, { loading, error }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "addVote">,
    FrankBackendTypes.MutationAddVoteArgs
  >(VOTE_ON_ACTION);

  const upvote = React.useCallback(
    async (action: string) => {
      await invokeAddVote({
        variables: {
          input: {
            campaignId,
            vote: 1,
            action,
          },
        },
      });
      if (afterVote) {
        afterVote();
      }
    },
    [invokeAddVote, campaignId, afterVote]
  );

  const downvote = React.useCallback(
    async (action: string) => {
      await invokeAddVote({
        variables: {
          input: {
            campaignId,
            vote: -1,
            action,
          },
        },
      });
      if (afterVote) {
        afterVote();
      }
    },
    [invokeAddVote, campaignId, afterVote]
  );

  return { upvote, downvote, loading, error };
}
