import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";
import pollFragment from "../pollFragment";

const mutation = gql`
  mutation ClosePoll($pollId: ID!) {
    closePoll(pollId: $pollId) {
      ...PollFragment
    }
  }
  ${pollFragment}
`;

export default function useClosePoll() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "closePoll">,
    FrankBackendTypes.MutationClosePollArgs
  >(mutation);

  const closePoll = React.useCallback(
    (pollId: string) => {
      return invokeMutation({
        variables: { pollId },
      });
    },
    [invokeMutation]
  );

  return {
    closePoll,
    loading,
    error,
    called,
  };
}
