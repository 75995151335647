import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { pendingMemberDataFragment } from "../queries/usePendingMembersListData";
import { FrankBackendTypes } from "frank-types";

const ADD_INDEPENDENT_AUTHENTICITY_VERIFICATION = gql`
  mutation AddIndependentAuthenticityVerification($coworkerId: ID!) {
    addIndependentAuthenticityVerification(coworkerId: $coworkerId) {
      id
      isNew
      coworker {
        ...PendingMemberData
      }
    }
  }
  ${pendingMemberDataFragment}
`;

export default function useIndependentAuthenticityVerification() {
  const [
    invokeAddIndependentAuthenticityVerification,
    { loading: loadingAddIndependentAuthenticityVerification },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "addIndependentAuthenticityVerification">
  >(ADD_INDEPENDENT_AUTHENTICITY_VERIFICATION);

  const addIndependentAuthenticityVerification = React.useCallback(
    async (coworkerId: FrankBackendTypes.Coworker["id"]) => {
      await invokeAddIndependentAuthenticityVerification({
        variables: {
          coworkerId,
        },
      });
    },
    [invokeAddIndependentAuthenticityVerification]
  );

  return {
    addIndependentAuthenticityVerification,
    loadingAddIndependentAuthenticityVerification,
  };
}
