/* eslint-disable no-underscore-dangle */
import { useLocation } from "react-router";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

export default function useBuilderNavLogic({
  workflowState,
}: {
  workflowState: FrankBackendTypes.CampaignWorkflowState;
}) {
  const steps = React.useMemo(() => {
    const _steps = [
      // "category",
      "title",
      "demand",
      "recipients",
      "attachments",
      "organizers",
      "settings",
    ];
    if (workflowState === FrankBackendTypes.CampaignWorkflowState.Draft) {
      _steps.push("publish");
    }
    return _steps;
  }, [workflowState]);

  const location = useLocation();
  const { pathname } = location;
  const currentStepIndex = steps.findIndex((step) => pathname.includes(step));
  const lastStepUrl = currentStepIndex > 0 && steps[currentStepIndex - 1];
  const nextStepUrl =
    currentStepIndex < steps.length && steps[currentStepIndex + 1];

  return {
    currentStepIndex,
    lastStepUrl,
    nextStepUrl,
    totalSteps: steps.length,
  };
}
