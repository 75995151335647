import { Avatar, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";

export const FriendlyFaces = ({
  faces,
  groupAvatar,
  groupName,
}: {
  faces: { url: string; altText: string }[];
  groupAvatar?: string;
  groupName: string;
}) => {
  const [first, second, third, fourth] = faces;
  return (
    <div className="flex -space-x-3 overflow-hidden items-center">
      <Avatar
        altText={first.altText}
        size={Sizes.MD}
        src={first.url}
        index={`z-${1}0`}
      />
      <Avatar
        altText={second.altText}
        size={Sizes.LG}
        src={second.url}
        index={`z-${2}0`}
      />
      <Avatar
        altText={groupName}
        size={Sizes.XL}
        src={groupAvatar || undefined}
        textPreview={groupAvatar ? undefined : groupName}
        index={`z-${3}0`}
      />
      <Avatar
        altText={third.altText}
        size={Sizes.LG}
        src={third.url}
        index={`z-${2}0`}
      />{" "}
      <Avatar
        altText={fourth.altText}
        size={Sizes.MD}
        src={fourth.url}
        index={`z-${1}0`}
      />
    </div>
  );
};
