import { Maybe } from "graphql/jsutils/Maybe";
import first from "lodash/first";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { ChatTypes } from "frank-types";

function reverse<T = any>(a: T[]) {
  return [...a].reverse();
}

type MessageState = {
  parent: Maybe<ChatTypes.Message>;
  messages: ChatTypes.Message[];
  messagesInFlight: Partial<ChatTypes.Message>[];
  typingUsers: { user: ChatTypes.User; timestamp: number }[];
  oldest: Date | null;
  newest: Date | null;
  loading: {
    editMessage: boolean;
    reactToMessage: boolean;
    getMessages: boolean;
    deleteMessage: boolean;
    sendMessage: boolean;
  };
  errors: {
    sendMessage: Maybe<string>;
    editMessage: Maybe<string>;
    reactToMessage: Maybe<string>;
    getMessages: Maybe<string>;
    deleteMessage: Maybe<string>;
  };
};

export const defaultMessageState: MessageState = {
  parent: null,
  messages: [],
  messagesInFlight: [],
  typingUsers: [],
  oldest: null,
  newest: null,
  loading: {
    editMessage: false,
    getMessages: false,
    reactToMessage: false,
    deleteMessage: false,
    sendMessage: false,
  },
  errors: {
    sendMessage: null,
    editMessage: null,
    getMessages: null,
    reactToMessage: null,
    deleteMessage: null,
  },
};

type MessageAction =
  | { type: "message-created"; message: ChatTypes.Message }
  | {
      type: "newer-messages-loaded";
      messages: ChatTypes.Message[];
      newest: Date;
      oldest: Date;
      parent: Maybe<ChatTypes.Message>;
    }
  | {
      type: "older-messages-loaded";
      messages: ChatTypes.Message[];
      newest: Date;
      oldest: Date;
      parent: Maybe<ChatTypes.Message>;
    }
  | {
      type: "messages-reset";
      messages: ChatTypes.Message[];
      newest: Date | null;
      oldest: Date | null;
      parent: Maybe<ChatTypes.Message>;
    }
  | { type: "user-typed"; user: ChatTypes.User; duration: number }
  | { type: "user-done-typing"; user: ChatTypes.User }
  | { type: "sending-message"; message: Partial<ChatTypes.Message> }
  | {
      type: "error";
      errorType: keyof MessageState["errors"];
      error: string;
      errorObject?: Error;
    }
  | { type: "finish-loading"; loadingType: keyof MessageState["loading"] }
  | { type: "loading"; loadingType: keyof MessageState["loading"] }
  | { type: "message-updated"; message: ChatTypes.Message }
  | { type: "parent-updated"; message: ChatTypes.Message };

function addUserToTypingArr(
  prevTyping: { user: ChatTypes.User; timestamp: number }[],
  user: ChatTypes.User
) {
  return sortBy(
    [
      ...prevTyping.filter((u) => u.user.id !== user.id),
      {
        user: user,
        timestamp: Date.now(),
      },
    ],
    (obj) => obj.user.displayName
  );
}

function processMessages(messages: ChatTypes.Message[]): ChatTypes.Message[] {
  return orderBy(uniqBy(messages, "id"), ["sentAt"], ["asc"]);
}

export function messageReducer(
  previousState: MessageState,
  action: MessageAction
): MessageState {
  if (action.type === "message-created") {
    // sometimes there's a socket hiccup and a message is added twice.
    // if we just added this message, ignore
    if (action.message.id === first(previousState.messages)?.id) {
      return previousState;
    }
    return {
      ...previousState,
      messages: processMessages([...previousState.messages, action.message]),
      messagesInFlight: previousState.messagesInFlight.filter(
        (messageInFlight) =>
          new Date(messageInFlight.sentAt).getTime() !==
          new Date(action.message.sentAt).getTime()
      ),
    };
  }
  if (action.type === "message-updated") {
    return {
      ...previousState,
      messages: [
        ...previousState.messages.map((m) => {
          if (m.id === action.message.id) {
            return action.message;
          }
          return m;
        }),
      ],
    };
  }
  if (action.type === "parent-updated") {
    return {
      ...previousState,
      parent: action.message,
    };
  }
  if (action.type === "error") {
    console.warn("error action in message reducer", action);
    return {
      ...previousState,
      loading: {
        ...previousState.loading,
        [action.errorType]: false,
      },
      errors: {
        ...previousState.errors,
        [action.errorType]: action.error,
      },
    };
  }
  if (action.type === "sending-message") {
    return {
      ...previousState,
      messagesInFlight: [...previousState.messagesInFlight, action.message],
    };
  }
  if (action.type === "loading") {
    return {
      ...previousState,
      loading: {
        ...previousState.loading,
        [action.loadingType]: true,
      },
      errors: {
        ...previousState.errors,
        [action.loadingType]: null,
      },
    };
  }
  if (action.type === "finish-loading") {
    return {
      ...previousState,
      loading: {
        ...previousState.loading,
        [action.loadingType]: false,
      },
    };
  }
  if (action.type === "newer-messages-loaded") {
    return {
      ...previousState,
      newest: action.newest,
      oldest: action.oldest,
      messages: processMessages([
        ...previousState.messages,
        ...reverse(action.messages),
      ]),
      parent: action.parent,
    };
  }
  if (action.type === "older-messages-loaded") {
    return {
      ...previousState,
      newest: action.newest,
      oldest: action.oldest,
      messages: processMessages([
        ...reverse(action.messages),
        ...previousState.messages,
      ]),
      parent: action.parent,
    };
  }
  if (action.type === "messages-reset") {
    return {
      ...previousState,
      newest: action.newest,
      oldest: action.oldest,
      messages: processMessages([...reverse(action.messages)]),
      parent: action.parent,
    };
  }
  if (action.type === "user-typed") {
    return {
      ...previousState,
      typingUsers: addUserToTypingArr(previousState.typingUsers, action.user),
    };
  }
  if (action.type === "user-done-typing") {
    return {
      ...previousState,
      typingUsers: previousState.typingUsers.filter((typingUserObj) => {
        return typingUserObj.user.id !== action.user.id;
      }),
    };
  }
  return previousState;
}
