import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import pollFragment from "../pollFragment";

const query = gql`
  query Poll($pollId: ID!) {
    poll(pollId: $pollId) {
      ...PollFragment
    }
  }
  ${pollFragment}
`;

export default function usePoll(pollId: string) {
  const { data, loading, error } = useQuery<
    Pick<FrankBackendTypes.Query, "poll">,
    { pollId: string }
  >(query, {
    variables: { pollId },
  });

  return { poll: data?.poll, loading, error };
}
