import * as React from "react";
import { ScreenSizes, useResponsive } from "@get-frank-eng/design-system";

const BuilderPetitionLayout = ({
  children,
  aboveDocumentContent,
  belowDocumentContent,
}: {
  children: React.ReactChildren | React.ReactChild;
  aboveDocumentContent?: any;
  belowDocumentContent?: any;
}) => {
  const { screenSize } = useResponsive();

  return (
    <>
      <div
        className="mx-auto md:my-24  transform -translate-x-0 md:-translate-x-40"
        // here we really shouldn't do any of this if we're in mobile
        style={
          screenSize < ScreenSizes.MD
            ? { width: "100%" }
            : { minHeight: "70vh", width: "680px" }
        }
      >
        {aboveDocumentContent}
        <div
          style={{ minHeight: "65vh" }}
          className="bg-canvas-700 full-formatting full-formatting px-0 md:px-8 pb-6 mb-12 md:border md:shadow-sm"
        >
          {children}
        </div>
        {belowDocumentContent}
      </div>
    </>
  );
};

export default BuilderPetitionLayout;
