import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const ACCEPT_COLLABORATOR_INVITE = gql`
  mutation AcceptCollaboratorInvite($campaignId: ID!) {
    acceptCollaboratorInvite(campaignId: $campaignId) {
      nothing
    }
  }
`;

const REJECT_COLLABORATOR_INVITE = gql`
  mutation RejectCollaboratorInvite($campaignId: ID!) {
    rejectCollaboratorInvite(campaignId: $campaignId) {
      nothing
    }
  }
`;

export function useCollaboratorInviteActions({
  campaignId,
}: {
  campaignId: string;
}) {
  const [invokeAccept, { loading: loadingAccept }] = useMutation<
    any,
    FrankBackendTypes.MutationAcceptCollaboratorInviteArgs
  >(ACCEPT_COLLABORATOR_INVITE);

  const [invokeReject, { loading: loadingReject }] = useMutation<
    any,
    FrankBackendTypes.MutationRejectCollaboratorInviteArgs
  >(REJECT_COLLABORATOR_INVITE);

  const accept = React.useCallback(async () => {
    await invokeAccept({ variables: { campaignId } });
  }, [invokeAccept, campaignId]);

  const reject = React.useCallback(async () => {
    await invokeReject({ variables: { campaignId } });
  }, [invokeReject, campaignId]);

  return { accept, reject, loadingReject, loadingAccept };
}

export default useCollaboratorInviteActions;
