import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";
import pollFragment from "../pollFragment";

const mutation = gql`
  mutation CreatePoll($input: CreatePollDTO!) {
    createPoll(input: $input) {
      ...PollFragment
    }
  }
  ${pollFragment}
`;

export default function useCreatePoll() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "createPoll">,
    FrankBackendTypes.MutationCreatePollArgs
  >(mutation);

  const createPoll = React.useCallback(
    async (input: FrankBackendTypes.CreatePollDto) => {
      const res = await invokeMutation({
        variables: { input },
      });
      return res.data?.createPoll;
    },
    [invokeMutation]
  );

  return {
    createPoll,
    loading,
    error,
    called,
  };
}
