import * as React from "react";

export default ({ classes }: { classes: string[] }) => {
  React.useEffect(() => {
    document.body.classList.add(...classes);
    return () => {
      document.body.classList.remove(...classes);
    };
  }, []);
  return null;
};
