import * as React from "react";
import * as ReactDOM from "react-dom";
import classNames from "classnames";

// export const Input = styled.input`
//   padding: 6px 14px;
//   font-size: 14px;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   min-width: 240px;
//   outline: none;
//   border: 2px solid palevioletred;
//   margin-right: auto;
// `;

// export const Button =
export const Button = ({ children, active, onMouseDown }) => (
  <button
    type="button"
    onMouseDown={onMouseDown}
    className={classNames([
      "mx-1",
      "hoverable",
      "rounded",
      "h-6",
      "border",
      "border-transparent",
      { "bg-canvas-500": active },
    ])}
  >
    {children}
  </button>
);

// export const IconButton = styled(Button)((props: any) => ({
//   color: props.active ? "mediumvioletred" : "lightpink",
//   border: "none",
//   padding: 0
// }));

export const IconButton = ({ active, children, onMouseDown }) => (
  <Button active={active} onMouseDown={onMouseDown}>
    {children}
  </Button>
);

export const Title = ({ children }) => (
  <h2 className="t-title-3">{children}</h2>
);

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

// export const Instance = styled.div<{ online: boolean }>`
//   background: ${props =>
//     props.online ? "rgba(128, 128, 128, 0.1)" : "rgba(247, 0, 0, 0.2)"};
//   padding: 20px 20px 30px;
// `;

export const ClientFrame = ({ children, minHeight }) => (
  <div style={{ minHeight }}>{children}</div>
);
