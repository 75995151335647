import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { Configuration, getConfig } from "./configuration";
import { setContext } from "@apollo/client/link/context";
import memoize from "lodash/memoize";

export let client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
});

export const getClient = memoize(
  async (configPromise: Promise<Configuration> = getConfig()) => {
    const config = await configPromise;
    const httpLink = createHttpLink({
      uri: config.apiUrl,
    });

    const authLink = setContext(async (_, { headers }) => {
      await config.waitForValidSession();
      return () => ({
        headers: {
          ...headers,
          authorization: config.getAuthorizationHeader(),
        },
      });
    });

    const c = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    client = c;

    return c;
  }
);
