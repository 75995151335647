"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Intent = exports.enumSizes = exports.sizeLabels = exports.Sizes = void 0;
var Sizes;
(function (Sizes) {
    Sizes[Sizes["XS"] = 0] = "XS";
    Sizes[Sizes["SM"] = 1] = "SM";
    Sizes[Sizes["MD"] = 2] = "MD";
    Sizes[Sizes["LG"] = 3] = "LG";
    Sizes[Sizes["XL"] = 4] = "XL";
})(Sizes = exports.Sizes || (exports.Sizes = {}));
exports.sizeLabels = ["xs", "sm", "md", "lg", "xl"];
exports.enumSizes = [Sizes.XS, Sizes.SM, Sizes.MD, Sizes.LG, Sizes.XL];
var Intent;
(function (Intent) {
    Intent["WARNING"] = "warn";
    Intent["SUCCESS"] = "success";
    Intent["FAILURE"] = "failure";
    Intent["INFO"] = "info";
    Intent["NONE"] = "none";
})(Intent = exports.Intent || (exports.Intent = {}));
