import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import {
  OnboardingCertification,
  OnboardingWorkflowState,
} from "frank-types/dist/frank-backend-types";
import * as React from "react";

const CONFIGURE_USER = gql`
  mutation ConfigureUser($input: ConfigureUserInput!) {
    configureUser(input: $input)
  }
`;

export default function useConfigureUser() {
  const [invokeConfigureUser, { loading, called, error }] = useMutation<
    OnboardingWorkflowState,
    FrankBackendTypes.MutationConfigureUserArgs
  >(CONFIGURE_USER);

  const configureUser = React.useCallback(
    async ({
      name,
      certifications = [],
    }: {
      name: string;
      certifications?: OnboardingCertification[];
    }) => {
      const result = await invokeConfigureUser({
        variables: { input: { name, certifications } },
      });
      return result.data;
    },
    [invokeConfigureUser]
  );

  return {
    configureUser,
    loading,
    called,
    error,
  };
}
