import { motion } from "framer-motion";
import * as React from "react";

const ProgressBar = () => {
  return (
    <motion.div
      className="bg-brand-300 h-1"
      initial={{ width: 0 }}
      animate={{ width: 250 }}
      transition={{ duration: 1.4 }}
    />
  );
};

const FullScreenLoading = ({ text }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, type: "tween" }}
    >
      <div className="bg-canvas-800 fixed h-screen w-screen flex flex-col justify-center items-center">
        <div className="t-regular mt-4 mb-8">{text}</div>
        <div style={{ width: 250 }}>
          <ProgressBar />
        </div>
      </div>
    </motion.div>
  );
};

export default FullScreenLoading;
