/* eslint-disable consistent-return */
import * as React from "react";

export const useScrollY = () => {
  const [scrollY, setScrollY] = React.useState<number | null>(null);

  const useSetScrollY = React.useCallback(() => {
    setScrollY(window.scrollY);
  }, [setScrollY]);

  React.useEffect(() => {
    window.addEventListener("scroll", useSetScrollY);
    return () => {
      window.removeEventListener("scroll", useSetScrollY);
    };
  }, [useSetScrollY]);

  return scrollY;
};

export function scrollWithOffset(element) {
  const y = element.getBoundingClientRect().top + window.scrollY - 50;

  window.scrollTo({
    top: y,
    behavior: "smooth"
  });
}
