import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MUTATION = gql`
  mutation RequestInvite($input: RequestInviteInput!) {
    requestInvite(input: $input) {
      nothing
    }
  }
`;

export default function useRequestInvite() {
  const [invokeRequestInvite, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "requestInvite">,
    FrankBackendTypes.MutationRequestInviteArgs
  >(MUTATION);
  const requestInvite = React.useCallback(
    async (input: FrankBackendTypes.MutationRequestInviteArgs["input"]) => {
      await invokeRequestInvite({ variables: { input } });
    },
    [invokeRequestInvite]
  );

  return { requestInvite, loading, error, called };
}
