import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import pollFragment from "../pollFragment";

const mutation = gql`
  mutation RespondToPoll($input: PollResponseDTO!) {
    respondToPoll(input: $input) {
      ...PollFragment
    }
  }
  ${pollFragment}
`;

export default function useRespondToPoll() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "respondToPoll">,
    FrankBackendTypes.MutationRespondToPollArgs
  >(mutation);

  const respondToPoll = React.useCallback(
    (input: FrankBackendTypes.PollResponseDto) => {
      return invokeMutation({
        variables: { input },
      });
    },
    [invokeMutation]
  );

  return {
    respondToPoll,
    loading,
    error,
    called,
  };
}
