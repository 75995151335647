import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query EmailPreferences {
    userEmailPreferences {
      mutedEmails
      muteAll
    }
  }
`;

export default function useGetEmailPreferences() {
  const { data, loading, error, refetch } = useQuery<{
    userEmailPreferences: FrankBackendTypes.EmailPreferences;
  }>(QUERY);

  return {
    emailPreferences: data?.userEmailPreferences,
    loadingEmailPreferences: loading,
    errorEmailPreferences: error,
    refetchEmailPreferences: refetch,
  };
}
