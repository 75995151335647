import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query template($id: ID!) {
    template(id: $id) {
      id
      title
      description
      about
      contentJSON
      attachments {
        id
        filename
        preview {
          url
          imageUrl
          title
          subtitle
        }
      }
      links {
        id
        preview {
          url
          imageUrl
          title
          subtitle
        }
      }
      categories {
        id
        name
      }
      author {
        id
        name
        bio
        longBio
        image {
          url
        }
      }
    }
  }
`;

export default function useGetTemplate(id: string) {
  const { loading, error, data } = useQuery<{
    template: FrankBackendTypes.Template;
  }>(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  });

  return {
    template: data?.template,
    loadingTemplate: loading,
    errorTemplate: error,
  };
}
