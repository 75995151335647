import { FrankBackendTypes } from "frank-types";

export const pendingMemberCoworkersRestriction: FrankBackendTypes.CoworkerFilterDto = {
  restrictions: [
    {
      column: FrankBackendTypes.CoworkerFilterableColummns.Status,
      combinator: FrankBackendTypes.Combinators.OneOf,
      in: ["in-group-review"],
    },
    {
      column: FrankBackendTypes.CoworkerFilterableColummns.YourTrustLevel,
      combinator: FrankBackendTypes.Combinators.IsNull,
    },
  ],
};

export const recentlyReviewedCoworkersRestrictions: FrankBackendTypes.CoworkerFilterDto = {
  restrictions: [
    {
      column: FrankBackendTypes.CoworkerFilterableColummns.YourTrustLevel,
      combinator: FrankBackendTypes.Combinators.IsNotNull,
    },
    {
      column: FrankBackendTypes.CoworkerFilterableColummns.YourTrustDate,
      combinator: FrankBackendTypes.Combinators.GreaterThan,
      stringValue: new Date(
        new Date().getTime() - 1000 * 60 * 60 * 24 * 7 // 1000 * 60 * 60 * 24 * 7 = one week
      ).toISOString(),
    },
    {
      column: FrankBackendTypes.CoworkerFilterableColummns.Status,
      combinator: FrankBackendTypes.Combinators.OneOf,
      in: ["in-group-review", "member", "rejected", "onboarding", "flagged"],
    },
  ],
};
