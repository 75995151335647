import { gql } from "@apollo/client";
import { getClient } from "../../gql-client";
import { ChatTypes } from "frank-types";

const messageFragment = gql`
  fragment MessageFragment on Message {
    id
    createdAt
    deletedAt
    isBotMessage
    isMyMessage
    sentAt
    body
    richObjects {
      id
      type
      url
      previewImageUrl
      foreignIdentifier
      title
      description
      source
      author
      dataJSON
    }
    replySummary {
      count
      authors {
        id
        displayName
        profilePic
      }
    }
    canReply
    channelId
    edited
    type
    reactions {
      type
      count
      youReacted
      users {
        id
        displayName
      }
    }
    author {
      id
      displayName
      profilePic
    }
  }
`;

export async function getMessage(
  messageId: string
): Promise<ChatTypes.Message> {
  const client = await getClient();
  const { data, errors } = await client.query<
    Pick<ChatTypes.Query, "message">,
    ChatTypes.QueryMessageArgs
  >({
    query: gql`
      query GetMessage($messageId: ID!) {
        message(messageId: $messageId) {
          ...MessageFragment
          parent {
            ...MessageFragment
          }
        }
      }
      ${messageFragment}
    `,
    variables: { messageId },
    fetchPolicy: "network-only",
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.message;
}

export async function getCursorForMessage(
  messageId: string
): Promise<{
  startOfPage: Date;
  channelId: string;
  parentId?: string;
}> {
  const client = await getClient();
  const { data } = await client.query<
    Pick<ChatTypes.Query, "message">,
    ChatTypes.QueryMessageArgs
  >({
    query: gql`
      query Message($messageId: ID!) {
        message(messageId: $messageId) {
          id
          startOfPage
          channelId
          parent {
            id
          }
        }
      }
    `,
    variables: {
      messageId,
    },
  });

  return {
    startOfPage: data.message.startOfPage,
    channelId: data.message.channelId,
    parentId: data.message.parent?.id,
  };
}

export async function getMessages({
  channelId,
  parentId,
  paginationInput,
}: {
  channelId: string;
  parentId?: string;
  paginationInput: ChatTypes.MessagePaginationInput;
}): Promise<{
  messages: ChatTypes.Messages;
  whoami: ChatTypes.User;
}> {
  const client = await getClient();
  const { data, errors } = await client.query<
    Pick<ChatTypes.Query, "messages"> & Pick<ChatTypes.Query, "whoami">,
    ChatTypes.QueryMessagesArgs
  >({
    query: gql`
      query Messages(
        $channelId: ID!
        $parentId: ID
        $paginationInput: MessagePaginationInput!
      ) {
        messages(
          channelId: $channelId
          parentId: $parentId
          paginationInput: $paginationInput
        ) {
          messages {
            objects {
              ...MessageFragment
            }
            oldest
            newest
          }
          parent {
            ...MessageFragment
          }
        }
      }
      ${messageFragment}
    `,
    variables: { channelId, parentId, paginationInput },
    fetchPolicy: "network-only",
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data;
}
