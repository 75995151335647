import * as React from "react";

const AlertEmptyState = ({ text }) => (
  <div
    className="flex flex-col justify-center"
    style={{
      height: "70vh",
    }}
  >
    <div className="t-small text-canvas-300 block mx-auto">{text}</div>
  </div>
);

export const EmptyState = ({ text }) => (
  <div className="text-canvas-400">{text}</div>
);

export default AlertEmptyState;
