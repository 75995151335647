import * as React from "react";

export default ({ offset = "large" }: { offset?: "large" | "medium" }) => {
  React.useEffect(() => {
    document.body.classList.add(`intercom-should-offset-on-mobile-${offset}`);
    return () => {
      document.body.classList.remove(
        `intercom-should-offset-on-mobile-${offset}`
      );
    };
  }, [offset]);

  return null;
};
