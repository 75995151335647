import classNames from "classnames";
import * as React from "react";
import Crossroads from "../../components/Crossroads";
import { useScrollY } from "../../hooks/useScrollY";
import LogoFull from "../../LogoFull";

const Top = ({ loginText }: { loginText?: any }) => {
  const yScroll = useScrollY();
  const [pageLocation, setPageLocation] = React.useState("top");
  return (
    <>
      <Crossroads
        onLeaving={() => setPageLocation("scrolled")}
        onEntering={() => {
          if (pageLocation === "scrolled") {
            setPageLocation("top");
          }
        }}
      />
      <>
        <div
          className={classNames([
            // positioning
            "sticky",
            "top-0",
            "w-screen",
            "h-16",
            "bg-canvas-800",
            "md:border-b-0",
            "md:bg-transparent",
            "z-20",
            "mb-6",

            // inner positioning
            "flex",
            "flex-row",
            "justify-between",
            "items-center",
            "px-4",
            "sm:px-6",

            {
              "border-b": yScroll > 0,
              "mobile-header-border": pageLocation === "scrolled",
            },
          ])}
        >
          <a target="_blank" href="https://getfrank.com">
            <LogoFull />
          </a>
          {loginText && (
            <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/login`}
              className="t-mini plus text-canvas-400 hover:text-canvas-300"
            >
              {loginText}
            </a>
          )}
        </div>
      </>
    </>
  );
};

export default Top;
