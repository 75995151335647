/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Slate, withReact, Editable } from "slate-react";
import { createEditor, Node } from "slate";
import { Element, Leaf } from "./EditorFrame";

export default ({ doc }: { doc: Node[] }) => {
  const editor = React.useMemo(() => withReact(createEditor()), []);
  const onChange = React.useCallback(() => {}, []);

  const renderElement = React.useCallback(
    (props: any) => <Element readonly {...props} />,
    []
  );

  const renderLeaf = React.useCallback(props => <Leaf {...props} />, []);
  return (
    <Slate onChange={onChange} editor={editor} value={doc}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
        style={{ overflowWrap: "anywhere" }}
      />
    </Slate>
  );
};
