import {
  Button,
  ErrorText,
  FormGroup,
  Input,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { StepLabel, StepTitle } from "../../SSO/components";
import useMissingCompany from "../dataAccess/mutations/useMissingCompany";
import { MinimalFormLayout } from "../layout";
import * as analytics from "../../analytics";
import { EventTypes } from "frank-types";
import useQueryParams from "../../hooks/useQueryParams";

type FormValues = {
  industry: string;
  locality: string;
  name: string;
  email?: string;
  domain?: string;
};

const MissingCompany = () => {
  const { state } = useLocation<{ queryText?: string }>();

  const { loading, error, missingCompany, called } = useMissingCompany();

  const { handleSubmit, register, errors } = useForm<FormValues>();

  const submit = useCallback(async (fv: FormValues) => {
    console.log({ fv });
    await missingCompany({
      industry: fv.industry,
      locality: fv.locality,
      name: fv.name,
      domain: fv.domain,
      email: fv.email,
      queryText: state.queryText,
    });
    analytics.capture(EventTypes.AnalyticsEvents.REQUEST_INVITATION);
  }, []);

  const qs = useQueryParams();

  const email = React.useMemo(() => {
    return qs.get("email");
  }, [qs]);

  const confirmation = (
    <>
      <div className="space-y-4">
        <div className="text-center">
          <i style={{ fontSize: "75px" }} className="material-icons-outlined">
            thumb_up
          </i>
          <StepTitle>Thanks for adding</StepTitle>
        </div>
        <p className="text-canvas-400 t-regular">
          Having better data about companies helps us support more workers like
          you. We'll add your employer to our database. If there are any groups
          on Frank matching your employer name, we'll let them know about
          membership requests if they express interest in receiving new members.
          You will be notified if this happens.
        </p>
      </div>
    </>
  );

  const form = (
    <>
      <div className="space-y-3">
        <div className="space-y-1">
          <StepLabel>Join a group</StepLabel>
          <StepTitle>Add missing company</StepTitle>
        </div>
        <p className="text-canvas-400 t-regular">
          Anonymously add your company to our database by sharing the following
          information. This will help other coworkers find your company on
          Frank.
        </p>
      </div>

      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        <div className="flex-1">
          <form onSubmit={handleSubmit(submit)} className="space-y-3">
            <FormGroup
              name="email"
              id="email"
              label="Personal email"
              className={`${email ? "hidden" : ""}`}
            >
              <Input
                register={register}
                errorText={
                  errors.email && (
                    <>
                      {errors.email.type === "pattern" && "Invalid email"}
                      {errors.email.type === "required" && "Email required"}
                    </>
                  )
                }
                registerArgs={{ required: true, pattern: /^\S+@\S+$/i }}
                type="email"
                value={email}
              />
            </FormGroup>
            <FormGroup id="name" name="name" label="Company name">
              <Input
                errorText={errors.name && "Company name required"}
                registerArgs={{ required: true }}
                register={register}
              />
            </FormGroup>

            <FormGroup id="locality" name="locality" label="Location">
              <Input
                errorText={errors.locality && "Location required"}
                registerArgs={{ required: true }}
                register={register}
                placeholder="City, State/Province, Country"
              />
            </FormGroup>

            <FormGroup id="domain" name="domain" label="Website">
              <Input
                register={register}
                type="text"
                placeholder="e.g. myemployer.com"
              />
            </FormGroup>

            <FormGroup id="industry" name="industry" label="Industry">
              <Input
                errorText={errors.industry && "Industry required"}
                registerArgs={{ required: true }}
                register={register}
                type="text"
              />
            </FormGroup>

            <Button
              buttonStyle="brand"
              loading={loading}
              type="submit"
              size={Sizes.LG}
            >
              Add
            </Button>
          </form>
        </div>
      </div>
    </>
  );

  return <MinimalFormLayout>{called ? confirmation : form}</MinimalFormLayout>;
};

export default MissingCompany;
