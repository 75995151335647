import { gql } from "@apollo/client";
import { getCursorForMessage } from "@get-frank-eng/chat-client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import client from "../gqlClient";

async function getBackendStateForChannel(channelId: string) {
  const { data } = await client.query<
    Pick<FrankBackendTypes.Query, "channel">,
    FrankBackendTypes.QueryChannelArgs
  >({
    variables: { channelId },
    query: gql`
      query Channel($channelId: ID!) {
        channel(channelId: $channelId) {
          campaignId
          topicId
          topicSlug
        }
      }
    `,
  });
  return data.channel;
}

const RouteToMessage = () => {
  const { messageId } = useParams<{ messageId: string }>();
  const [messageRedirectState, setMessageRedirectState] = React.useState<{
    startOfPage: Date;
    channelId: string;
    parentId?: string;
  }>();

  const [
    backendRedirectState,
    setBackendRedirectState,
  ] = React.useState<FrankBackendTypes.ChatChannel>();

  React.useEffect(() => {
    getCursorForMessage(messageId).then((rs) => setMessageRedirectState(rs));
  }, [messageId, setMessageRedirectState]);

  React.useEffect(() => {
    if (messageRedirectState) {
      getBackendStateForChannel(
        messageRedirectState.channelId
      ).then((backendState) => setBackendRedirectState(backendState));
    }
  }, [messageRedirectState]);

  if (messageRedirectState && backendRedirectState) {
    const threadPart = messageRedirectState.parentId
      ? `/thread/${messageRedirectState.parentId}`
      : "";
    const queryString = `?startOfPage=${messageRedirectState.startOfPage}&messageId=${messageId}`;
    if (backendRedirectState.campaignId) {
      return (
        <Redirect
          to={`/campaigns/${backendRedirectState.campaignId}${threadPart}${queryString}`}
        />
      );
    }

    if (backendRedirectState.topicId) {
      return (
        <Redirect
          to={`/topics/${backendRedirectState.topicSlug}${threadPart}${queryString}`}
        />
      );
    }
  }

  return null;
};

export default RouteToMessage;
