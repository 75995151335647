import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query Campaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id
      title
      workflowState
      signatureGoal
      signatureCount
      campaignOrganizers {
        id
        shortName
        profilePic {
          id
          url
        }
      }
      relationshipToMe
      publishingValidationIssues {
        category
        message
      }
    }
  }
`;

export default function useCampaign({ campaignId }: { campaignId: string }) {
  const { data, loading, error, refetch, called } = useQuery<{
    campaign: FrankBackendTypes.Campaign;
  }>(QUERY, {
    variables: { campaignId },
  });

  return {
    calledCampaign: called,
    campaign: data?.campaign,
    loadingCampaign: loading,
    errorCampaign: error,
    refetchCampaign: refetch,
  };
}
