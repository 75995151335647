import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import gfm from 'remark-gfm';
import sanitize from 'rehype-sanitize';
import remark2rehype from 'remark-rehype';
import md from 'remark-parse';
import unified from 'unified';
import html from 'rehype-stringify';

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _createForOfIteratorHelperLoose(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];
  if (it) return (it = it.call(o)).next.bind(it);

  if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
    if (it) o = it;
    var i = 0;
    return function () {
      if (i >= o.length) return {
        done: true
      };
      return {
        done: false,
        value: o[i++]
      };
    };
  }

  throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

var mentionDataRegex = /@\(([\w\s\-!#]+)\)\(([\w'\s\-\.!#]+)\)\(([\w\s\-!#]+)\)/g;
function parseMention(mention) {
  var match = /@\(([\w\s\-!#]+)\)\(([\w'\s\-\.!#]+)\)\(([\w\s\-!#]+)\)/g.exec(mention);

  if (!match) {
    return null;
  }

  var type = match[1],
      display = match[2],
      id = match[3];
  return {
    type: type,
    display: display,
    id: id
  };
}
function findMentions(text) {
  return uniq(text.match(mentionDataRegex));
}
function mentionIdsByType(text) {
  return mapValues(groupBy(findMentions(text).map(parseMention).filter(function (mention) {
    return !!mention;
  }), "type"), function (arr) {
    return arr.filter(function (v) {
      return !!v;
    }).map(function (mention) {
      return mention.id;
    });
  });
}

function componentJoin(arr, insert) {
  return arr.reduce(function (acc, item) {
    return [].concat(acc, [item, insert]);
  }, []).slice(0, -1);
}

function addMentionsToMarkdownString(_ref) {
  var text = _ref.text,
      renderMention = _ref.renderMention;
  var parsed;
  var mentionified = [];
  unified().use(md).use(gfm).use(remark2rehype).use(html).use(sanitize).process(text, function (err, file) {
    parsed = String(file);
    mentionified = addMentionsToString({
      text: parsed,
      renderMention: renderMention
    });
  });
  return mentionified.join("");
}
function addMentionsToString(_ref2) {
  var text = _ref2.text,
      renderMention = _ref2.renderMention;
  var parts = findMentions(text);

  if (!parts) {
    return [text];
  }

  var stringsAndComponents = [text];

  for (var _iterator = _createForOfIteratorHelperLoose(parts), _step; !(_step = _iterator()).done;) {
    var part = _step.value;
    var mentionDataForPart = parseMention(part);

    if (mentionDataForPart) {
      // eslint-disable-next-line no-plusplus
      for (var i = 0; i < stringsAndComponents.length; i++) {
        var subStr = stringsAndComponents[i];

        if (typeof subStr === "string") {
          stringsAndComponents = [].concat(stringsAndComponents.slice(0, i), componentJoin(subStr.split(part), renderMention(mentionDataForPart)), stringsAndComponents.slice(i + 1, stringsAndComponents.length));
        }
      }
    }
  }

  return stringsAndComponents;
}

function getIndicesOf(searchStr, str) {
  var searchStrLen = searchStr.length;

  if (searchStrLen === 0) {
    return [];
  }

  var startIndex = 0;
  var index;
  var indices = []; // eslint-disable-next-line no-cond-assign

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }

  return indices;
}

function findInMixedArr(mixedArr, search) {
  var locations = [];

  var _loop = function _loop(i) {
    var currentNode = mixedArr[i];

    if (typeof currentNode === "string") {
      var indexes = getIndicesOf(search, currentNode);
      locations.push.apply(locations, indexes.map(function (num) {
        return {
          mentionIdx: i,
          charCount: num
        };
      }));
    }
  };

  for (var i = 0; i < mixedArr.length; i++) {
    _loop(i);
  }

  return locations;
}
function findLineNumbersIntersectingWithSelection(richStr, plainStart, plainEnd) {
  var lines = richStr.split("\n");

  if (lines.length === 1) {
    return [0];
  }

  var _lines$reduce = lines.reduce(function (_ref, line, i) {
    var lineNumbers = _ref.lineNumbers,
        charCount = _ref.charCount;
    var toReturn = {
      lineNumbers: lineNumbers,
      charCount: charCount
    };
    var newLineBetweenCursors = plainStart < charCount && plainEnd > charCount;
    var sameLineAsStart = plainStart > charCount && plainStart < charCount + line.length;
    var sameLineAsEnd = plainEnd > charCount && plainEnd < charCount + line.length;
    var cursorAtEndOfLine = line.length === plainStart - 1 && plainStart === plainEnd;

    if (newLineBetweenCursors || sameLineAsStart || sameLineAsEnd || cursorAtEndOfLine) {
      toReturn.lineNumbers.push(i);
    }

    toReturn.charCount += line.length;
    return toReturn;
  }, {
    charCount: 0,
    lineNumbers: []
  }),
      lineNumbersToReturn = _lines$reduce.lineNumbers;

  return lineNumbersToReturn;
}

function translatePlainTextCursorToRichTextNumericCursor(_ref2) {
  var mentionsAndStrings = _ref2.mentionsAndStrings,
      plainTextSelectionCursor = _ref2.plainTextSelectionCursor;
  var plainCharCounter = 0;
  var richCounter = 0;

  for (var i = 0; i < mentionsAndStrings.length; i++) {
    var currentNode = mentionsAndStrings[i];

    if (typeof currentNode !== "string") {
      plainCharCounter += currentNode.display.length;
      richCounter += formatMention(currentNode).length;
    } else {
      for (var j = 0; j < currentNode.length; j++) {
        if (plainCharCounter >= plainTextSelectionCursor) {
          return richCounter;
        }

        plainCharCounter++;
        richCounter++;
      }
    }
  }

  return -1;
}

function translatePlainTextCursorToLocation(mentionsAndStrings, plainTextSelectionCursor, offset) {
  if (offset === void 0) {
    offset = 0;
  }

  plainTextSelectionCursor += offset;
  var charCounter = 0;

  for (var i = 0; i < mentionsAndStrings.length; i++) {
    var currentNode = mentionsAndStrings[i];

    if (typeof currentNode !== "string") {
      charCounter += currentNode.display.length;
    } else {
      if (charCounter + currentNode.length >= plainTextSelectionCursor) {
        return {
          mentionIdx: i,
          charCount: plainTextSelectionCursor - charCounter
        };
      }

      charCounter += currentNode.length;
    }
  }

  throw new Error("could not find");
}

function injectIntoString(parentStr, loc, toInsert) {
  var before = parentStr.slice(0, loc);
  var after = parentStr.slice(loc);
  return "" + before + toInsert + after;
}

function addTextToSelectionCursorLocation(_ref3) {
  var toInsert = _ref3.toInsert,
      selection = _ref3.selection,
      mentionsAndStrings = _ref3.mentionsAndStrings;
  return mentionsAndStrings.map(function (fragment, i) {
    if (i !== selection.mentionIdx) {
      return fragment;
    }

    if (typeof fragment !== "string") {
      throw new Error("somethings wrong idk");
    }

    return injectIntoString(fragment, selection.charCount, toInsert);
  });
}

function prependLinesIntersectingWithSelection(_ref4) {
  var richStr = _ref4.richStr,
      plainCursorEnd = _ref4.plainCursorEnd,
      plainCursorStart = _ref4.plainCursorStart,
      toInsert = _ref4.toInsert;
  var mentionsAndStrings = makeMixedArrFromRichStr(richStr); // these are the line numbers to add a dash to

  var lineOverlapsNumbers = findLineNumbersIntersectingWithSelection(richStr, plainCursorStart, plainCursorEnd);

  for (var _iterator = _createForOfIteratorHelperLoose(lineOverlapsNumbers), _step; !(_step = _iterator()).done;) {
    var lineNo = _step.value;
    var partlyTransformedMixedArrByLine = transformMixedArrayToRichString(mentionsAndStrings).split("\n");
    partlyTransformedMixedArrByLine[lineNo] = injectIntoString(partlyTransformedMixedArrByLine[lineNo], 0, toInsert);
    mentionsAndStrings = makeMixedArrFromRichStr(partlyTransformedMixedArrByLine.join("\n"));
  }

  return transformMixedArrayToRichString(mentionsAndStrings);
}
function makeMixedArrFromRichStr(richStr) {
  return addMentionsToString({
    text: richStr,
    renderMention: function renderMention(x) {
      return x;
    }
  });
}
function replaceSelectedText(_ref5) {
  var toInsert = _ref5.toInsert,
      stringWithMentions = _ref5.stringWithMentions,
      plainSelectionEnd = _ref5.plainSelectionEnd,
      plainSelectionStart = _ref5.plainSelectionStart;
  var mentionsAndStrings = makeMixedArrFromRichStr(stringWithMentions);
  var richStartCursor = translatePlainTextCursorToRichTextNumericCursor({
    mentionsAndStrings: mentionsAndStrings,
    plainTextSelectionCursor: plainSelectionStart
  });
  var richEndCursor = translatePlainTextCursorToRichTextNumericCursor({
    mentionsAndStrings: mentionsAndStrings,
    plainTextSelectionCursor: plainSelectionEnd
  });
  var richStr = transformMixedArrayToRichString(mentionsAndStrings);
  var before = richStartCursor > 0 ? richStr.slice(0, richStartCursor) : "";
  var after = richEndCursor > 0 ? richStr.slice(richEndCursor) : "";
  return "" + before + toInsert + after;
}
function insertIntoMentionsString(_ref6) {
  var toInsert = _ref6.toInsert,
      stringWithMentions = _ref6.stringWithMentions,
      plainSelectionEnd = _ref6.plainSelectionEnd,
      plainSelectionStart = _ref6.plainSelectionStart;
  var mentionsAndStrings = makeMixedArrFromRichStr(stringWithMentions);
  var startCursor = translatePlainTextCursorToLocation(mentionsAndStrings, plainSelectionStart);
  mentionsAndStrings = addTextToSelectionCursorLocation({
    toInsert: toInsert,
    selection: startCursor,
    mentionsAndStrings: mentionsAndStrings
  });

  if (plainSelectionEnd) {
    var endCursor = translatePlainTextCursorToLocation(mentionsAndStrings, plainSelectionEnd, toInsert.length);
    mentionsAndStrings = addTextToSelectionCursorLocation({
      toInsert: toInsert,
      selection: endCursor,
      mentionsAndStrings: mentionsAndStrings
    });
  }

  return transformMixedArrayToRichString(mentionsAndStrings);
}

function formatMention(mention) {
  return "@(" + mention.type + ")(" + mention.display + ")(" + mention.id + ")";
}

function transformMixedArrayToRichString(mixedArr) {
  return mixedArr.map(function (mentionOrString) {
    if (typeof mentionOrString === "string") {
      return mentionOrString;
    }

    return formatMention(mentionOrString);
  }).join("");
}

export { addMentionsToMarkdownString, addMentionsToString, findInMixedArr, findLineNumbersIntersectingWithSelection, findMentions, insertIntoMentionsString, makeMixedArrFromRichStr, mentionDataRegex, mentionIdsByType, parseMention, prependLinesIntersectingWithSelection, replaceSelectedText };
