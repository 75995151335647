import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const SET_CERTIFICATIONS = gql`
  mutation SetCertifications(
    $onboardingId: ID!
    $certifications: [OnboardingCertification!]!
  ) {
    selfCertifyNotManagement(
      onboardingId: $onboardingId
      certifications: $certifications
    ) {
      nothing
    }
  }
`;

export default function useCertify({
  onboardingId,
}: {
  onboardingId: FrankBackendTypes.Onboarding["id"];
}) {
  const [invokeSetCertifications, { loading }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "selfCertifyNotManagement">,
    FrankBackendTypes.MutationSelfCertifyNotManagementArgs
  >(SET_CERTIFICATIONS);

  const setCertifications = React.useCallback(
    async (certifications: FrankBackendTypes.OnboardingCertification[]) => {
      const { data } = await invokeSetCertifications({
        variables: {
          onboardingId,
          certifications,
        },
      });
      return data;
    },
    [invokeSetCertifications, onboardingId]
  );

  return { setCertifications, loading };
}
