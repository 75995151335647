import * as React from "react";
import AuthN from "./AuthN";
import useDebounce from "../hooks/useDebounce";

export default function usePasswordStrength(password: string): number {
  const debouncedPassword = useDebounce<string>(password, 250);
  const [strength, setStrength] = React.useState<number>(-1);

  React.useEffect(() => {
    if (!debouncedPassword) {
      setStrength(-1);
      return;
    }
    AuthN.passwordStrength(debouncedPassword)
      .then((s) => {
        setStrength(s);
      })
      .catch((e) => {
        console.warn("error checking pw strength", e);
      });
  }, [debouncedPassword, setStrength]);

  return strength;
}
