import { useMutation, gql } from "@apollo/client";
import * as React from "react";

const ACCEPT_NO_MGMT = gql`
  mutation AcceptNoMgmtInvite {
    acceptNoMgmtInvite {
      nothing
    }
  }
`;

export const useAcceptNoMgmtAgreement = ({
  onAccept,
}: {
  onAccept?: () => void;
}) => {
  const [invokeAcceptNoMgmt, { loading, error }] = useMutation(ACCEPT_NO_MGMT);
  const acceptNoMgmt = React.useCallback(async () => {
    await invokeAcceptNoMgmt();
    if (onAccept) {
      onAccept();
    }
  }, [invokeAcceptNoMgmt, onAccept]);

  return {
    acceptNoMgmt,
    error,
    loading,
  };
};
