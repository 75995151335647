import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const ADD = gql`
  mutation AddLink($input: CreateCampaignLinkDTO!) {
    createCampaignLink(input: $input) {
      nothing
    }
  }
`;

const DELETE = gql`
  mutation RemoveLink($campaignId: ID!, $campaignLinkId: ID!) {
    deleteCampaignLink(
      campaignId: $campaignId
      campaignLinkId: $campaignLinkId
    ) {
      nothing
    }
  }
`;

export default function useCampaignLinks({
  campaignId,
}: {
  campaignId: string;
}) {
  const [invokeAdd, { loading, error }] = useMutation<
    any,
    FrankBackendTypes.MutationCreateCampaignLinkArgs
  >(ADD);

  const [
    invokeDelete,
    { loading: loadingDelete, error: errorDelete },
  ] = useMutation<any, FrankBackendTypes.MutationDeleteCampaignLinkArgs>(
    DELETE
  );

  const deleteLink = React.useCallback(
    async (linkId: string) => {
      await invokeDelete({ variables: { campaignId, campaignLinkId: linkId } });
    },
    [invokeDelete, campaignId]
  );

  const addLink = React.useCallback(
    async (url: string) => {
      await invokeAdd({
        variables: {
          input: {
            campaignId,
            url,
          },
        },
      });
    },
    [campaignId, invokeAdd]
  );

  return {
    addLink,
    loadingAddLink: loading,
    errorAddLink: error,
    deleteLink,
    loadingDelete,
    errorDelete,
  };
}
