import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const ENTER_PASSPHRASE = gql`
  mutation EnterPassphrase($onboardingId: ID!, $passphrase: String!) {
    enterPassphrase(onboardingId: $onboardingId, passphrase: $passphrase)
  }
`;

export default function useEnterPassphrase({
  onboardingId,
}: {
  onboardingId: FrankBackendTypes.Onboarding["id"];
}) {
  const [invokeEnterPassphrase, { loading }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "enterPassphrase">,
    FrankBackendTypes.MutationEnterPassphraseArgs
  >(ENTER_PASSPHRASE);

  const enterPassphrase = React.useCallback(
    async (passphrase: string): Promise<boolean> => {
      const { data } = await invokeEnterPassphrase({
        variables: {
          onboardingId,
          passphrase,
        },
      });
      return data.enterPassphrase;
    },
    [invokeEnterPassphrase, onboardingId]
  );

  return { enterPassphrase, loading };
}
