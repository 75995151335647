import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const SEND_CONFIRMATION_EMAIL = gql`
  mutation SendConfirmationEmail {
    sendConfirmationEmail {
      nothing
    }
  }
`;

export default function useSendConfirmationEmail() {
  const [invokeSendConfirmationEmail, { loading }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "sendConfirmationEmail">
  >(SEND_CONFIRMATION_EMAIL);

  const sendConfirmationEmail = React.useCallback(async () => {
    const { data } = await invokeSendConfirmationEmail();
    return data;
  }, [invokeSendConfirmationEmail]);

  return { sendConfirmationEmail, loading };
}
