import { gql } from "@apollo/client";

const pollFragment = gql`
  fragment PollFragment on Poll {
    id
    title
    expiration
    expiresIn
    status
    relationshipToMe
    myResponse {
      id
      name
    }
    canChangeResponse
    options {
      id
      name
      percent
      totalVotes
    }
    user {
      id
      name
      profilePic {
        id
        url
      }
    }
  }
`;

export default pollFragment;
