import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MUTE_EMAIL = gql`
  mutation MuteEmail($emailType: EmailTemplates!) {
    muteEmail(emailType: $emailType) {
      nothing
    }
  }
`;

const UNMUTE_ALL = gql`
  mutation unmuteAll {
    unmuteAllEmail {
      nothing
    }
  }
`;

const UNMUTE_EMAIL = gql`
  mutation UnmuteEmail($emailType: EmailTemplates!) {
    unmuteEmail(emailType: $emailType) {
      nothing
    }
  }
`;

const MUTE_ALL = gql`
  mutation muteAll {
    muteAllEmail {
      nothing
    }
  }
`;

export default function useUpdateEmailPreferences(afterUpdate) {
  const [
    invokeMuteEmail,
    {
      loading: muteEmailLoading,
      called: muteEmailCalled,
      error: muteEmailError,
    },
  ] = useMutation<any, { emailType: FrankBackendTypes.EmailTemplates }>(
    MUTE_EMAIL
  );

  const muteEmail = React.useCallback(
    async (emailType: FrankBackendTypes.EmailTemplates) => {
      await invokeMuteEmail({
        variables: {
          emailType,
        },
      });
      await afterUpdate();
    },
    [invokeMuteEmail, afterUpdate]
  );

  const [
    invokeUnmuteEmail,
    {
      loading: unmuteEmailLoading,
      called: unmuteEmailCalled,
      error: unmuteEmailError,
    },
  ] = useMutation<any, { emailType: FrankBackendTypes.EmailTemplates }>(
    UNMUTE_EMAIL
  );

  const unmuteEmail = React.useCallback(
    async (emailType: FrankBackendTypes.EmailTemplates) => {
      await invokeUnmuteEmail({
        variables: {
          emailType,
        },
      });
      await afterUpdate();
    },
    [invokeUnmuteEmail, afterUpdate]
  );

  const [
    invokeMuteAll,
    { loading: muteAllLoading, called: muteAllCalled, error: muteAllError },
  ] = useMutation<any>(MUTE_ALL);

  const muteAll = React.useCallback(async () => {
    await invokeMuteAll();
    await afterUpdate();
  }, [invokeMuteAll, afterUpdate]);

  const [
    invokeUnmuteAll,
    {
      loading: unmuteAllLoading,
      called: unmuteAllCalled,
      error: unmuteAllError,
    },
  ] = useMutation<any>(UNMUTE_ALL);

  const unmuteAll = React.useCallback(async () => {
    await invokeUnmuteAll();
    await afterUpdate();
  }, [invokeUnmuteAll, afterUpdate]);

  return {
    muteEmail,
    muteEmailLoading,
    muteEmailCalled,
    muteEmailError,
    unmuteEmail,
    unmuteEmailLoading,
    unmuteEmailCalled,
    unmuteEmailError,
    muteAll,
    muteAllLoading,
    muteAllCalled,
    muteAllError,
    unmuteAll,
    unmuteAllLoading,
    unmuteAllCalled,
    unmuteAllError,
  };
}
