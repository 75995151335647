import { ModalBody, ModalHeader } from "@get-frank-eng/design-system";
import * as React from "react";
import CampaignBuilderSidebar from "../CampaignBuilderSidebar";

export default function CampaignBuilderMobileNavModal({ campaign }) {
  return (
    <>
      <ModalHeader title="" />
      <ModalBody>
        <CampaignBuilderSidebar showSaveAndExit={false} campaign={campaign} />
        <div className="flex-shrink-0" style={{ height: "25vh" }} />
      </ModalBody>
    </>
  );
}
