import { MentionTrigger } from "@get-frank-eng/design-system";
import { ChatTypes } from "frank-types";

export const mentionTriggerGen = (
  channels: ChatTypes.Channel[]
): MentionTrigger[] => [
  {
    trigger: "#",
    type: "channel",
    data: channels.map((ch) => ({
      display: ch.name,
      id: ch.id,
    })),
  },
];
