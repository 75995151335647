import { Icon, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";

const TaskRow = ({
  title,
  description,
  complete,
  showWarning,
  dataCy,
}: {
  title: string;
  description: any;
  complete: boolean;
  showWarning?: boolean;
  dataCy?: string;
}) => (
  <div
    className="flex flex-row items-center border-b last:border-b-0 p-4"
    data-cy={dataCy}
  >
    <div className="flex-grow">
      <div className="t-mini plus">{title}</div>
      <div className="t-mini text-canvas-400">{description}</div>
    </div>
    <div className="ml-3">
      {complete && !showWarning ? (
        <Icon icon="check" size={Sizes.MD} classNames="text-frank-green-300" />
      ) : (
        <Icon
          icon="warning"
          size={Sizes.MD}
          classNames={
            showWarning ? "text-frank-red-300" : "text-frank-gold-300"
          }
        />
      )}
    </div>
  </div>
);

export default TaskRow;
