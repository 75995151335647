import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const WAITING_ROOM_QUERY = gql`
  query WaitingRoom {
    onboarding {
      id
      workflowState
      validationEmail
      group {
        id
        name
        company
      }
      inviteType
      requiresPassphrase
      requiresPassphrase
      issues {
        issueList
        authenticityIssuesVisibleToOnboarder {
          type
        }
        trustworthinessIssuesVisibleToOnboarder {
          type
        }
        eligibilityIssuesVisibleToOnboarder {
          type
        }
        shouldAllowTrust
        authenticityIndependentlyVerified
      }
      certifications
      inviter {
        name
      }
    }
  }
`;

export default function useWaitingRoomQuery() {
  const { data, loading, error, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "onboarding">
  >(WAITING_ROOM_QUERY);

  return { data: data?.onboarding, loading, error, refetch };
}
