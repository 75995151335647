import * as React from "react";
import { Portal } from "./Components";
import useClickOutside from "../hooks/useClickOutside";

export default ({
  selection,
  children,
  onClickOutside,
}: {
  selection: Selection;
  children: React.ReactChild;
  onClickOutside: () => void;
}) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const rect = React.useMemo(() => {
    const domRange = selection.getRangeAt(0);

    return domRange.getBoundingClientRect();
  }, [selection]);

  React.useEffect(() => {
    const el = containerRef.current;
    if (!el) {
      return;
    }
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
    containerRef.current.querySelector<HTMLElement>("input, textarea").focus();
  }, [containerRef, rect]);

  useClickOutside({ ref: containerRef, onClickOutside });

  return (
    <Portal>
      <div
        className="transform absolute p-1 bg-frank-green-600 opacity-25"
        style={{
          top: `${rect.top}px`,
          left: `${rect.left}px`,
          height: `${rect.height}px`,
          width: `${rect.width}px`,
        }}
      />
      <div
        ref={containerRef}
        className="transform -translate-y-2 border absolute transition-opacity duration-200 z-50 shadow-xs-and-l rounded bg-canvas-600 p-2 flex flex-row items-center"
      >
        {children}
      </div>
    </Portal>
  );
};
