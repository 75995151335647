import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const COMMENT = gql`
  mutation PostComment($input: PostCommentDTO!) {
    campaignComment(input: $input) {
      nothing
    }
  }
`;

export default function usePostComment() {
  const [invokePostComment, { loading }] = useMutation<
    any,
    FrankBackendTypes.MutationCampaignCommentArgs
  >(COMMENT);

  return { invokePostComment, loading };
}
