import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const FINISH_ONBOARDING = gql`
  mutation FinishOnboarding($onboardingId: ID!) {
    finishOnboarding(onboardingId: $onboardingId) {
      id
      workflowState
    }
  }
`;

export default function useFinishOnboarding({
  onboardingData,
}: {
  onboardingData: FrankBackendTypes.Onboarding;
}) {
  const [invokeFinishOnboarding] = useMutation<
    { finishOnboarding: FrankBackendTypes.OnboardingIssue[] },
    FrankBackendTypes.MutationFinishOnboardingArgs
  >(FINISH_ONBOARDING);

  const finishOnboarding = React.useCallback(async () => {
    const { data } = await invokeFinishOnboarding({
      variables: {
        onboardingId: onboardingData.id,
      },
    });
    return data.finishOnboarding;
  }, [onboardingData, invokeFinishOnboarding]);

  return { finishOnboarding };
}
