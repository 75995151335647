import { FullCenter } from "@get-frank-eng/design-system";
import * as React from "react";
import Loading from "../components/Loading";
import useOnboardingQuery from "./dataAccess/queries/useOnboardingQuery";
import Onboarding from "./OnboardingMain";

export default function FinishOnboardingLater() {
  const {
    onboardingData,
    onboardingLoading,
    onboardingError,
    onboardingRefetch
  } = useOnboardingQuery();

  if (onboardingLoading) {
    return (
      <div className="h-screen">
        <FullCenter>
          <Loading />
        </FullCenter>
      </div>
    );
  }

  if (onboardingError) {
    throw new Error(onboardingError.message);
  }

  return (
    <Onboarding
      onboardingData={onboardingData}
      onboardingRefetch={onboardingRefetch}
      token={onboardingData.token}
    />
  );
}
