import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import MissingCompany from "./pages/MissingCompany";
import RequestInvite from "./pages/RequestInvite";

const CreateNewGroup = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/missing-company`}>
        <MissingCompany />
      </Route>
      <RequestInvite />
    </Switch>
  );
};

export default CreateNewGroup;
