import { useChannels } from "@get-frank-eng/chat-client";
import { ChatTypes } from "frank-types";
import * as React from "react";

export function useCursorToDisplay({
  channelId,
  messagesInFlight,
}: {
  channelId: string;
  messagesInFlight: Partial<ChatTypes.Message>[];
}) {
  const { channels } = useChannels();
  const [cursorToDisplay, setCursorToDisplay] = React.useState<Date>(
    channels.find((ch) => ch.id === channelId)?.cursor
  );
  const [cursorCleared, setCursorCleared] = React.useState(false);

  React.useEffect(() => {
    if (channels.length && !cursorToDisplay) {
      setCursorToDisplay(channels.find((ch) => ch.id === channelId)?.cursor);
    }
  }, [cursorToDisplay, setCursorToDisplay, channels, channelId]);

  const clearCursor = React.useCallback(() => setCursorCleared(true), [
    setCursorCleared,
  ]);

  React.useEffect(() => {
    if (messagesInFlight.length) {
      clearCursor();
    }
  }, [messagesInFlight, clearCursor]);

  return {
    cursorToDisplay: cursorCleared ? null : cursorToDisplay,
  };
}
