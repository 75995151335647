import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

import topicFragment from "../topicFragment";

const mutation = gql`
  mutation CreateTopic($input: CreateTopicInput!) {
    createTopic(input: $input) {
      ...TopicFragment
    }
  }
  ${topicFragment}
`;

export default function useCreateTopic() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "createTopic">,
    FrankBackendTypes.MutationCreateTopicArgs
  >(mutation);

  const createTopic = React.useCallback(
    async (input: FrankBackendTypes.CreateTopicInput) => {
      const { data } = await invokeMutation({
        variables: { input },
      });
      return data?.createTopic;
    },
    [invokeMutation]
  );

  return {
    createTopic,
    loading,
    error,
    called,
  };
}
