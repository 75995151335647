import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import attachmentFragment from "../attachmentFragment";

const query = gql`
  query Attachment($attachmentId: ID!) {
    attachment(attachmentId: $attachmentId) {
      ...AttachmentFragment
    }
  }
  ${attachmentFragment}
`;

export default function useAttachment(attachmentId: string) {
  const { data, loading, error } = useQuery<
    Pick<FrankBackendTypes.Query, "attachment">,
    { attachmentId: string }
  >(query, {
    variables: { attachmentId },
  });

  return { attachment: data?.attachment, loading, error };
}
