import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const CREATE_CUSTOM_ACTION = gql`
  mutation CreateCustomAction($input: CreateCustomActionDTO!) {
    createCustomAction(input: $input) {
      nothing
    }
  }
`;

export default function useCustomAction({
  campaignId,
  afterPost,
}: {
  campaignId: string;
  afterPost?: () => any;
}) {
  const [invokeCreateCustomAction, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationCreateCustomActionArgs
  >(CREATE_CUSTOM_ACTION);

  const createCustomAction = React.useCallback(
    async ({ description, title }: { description: string; title: string }) => {
      await invokeCreateCustomAction({
        variables: { input: { campaignId, description, title } },
      });
      if (afterPost) {
        afterPost();
      }
    },
    [invokeCreateCustomAction, campaignId, afterPost]
  );

  return {
    createCustomAction,
    loading,
    called,
    error,
  };
}
