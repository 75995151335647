import * as React from "react";
import AuthN from "./AuthN";
import { EventEmitter2 } from "eventemitter2";
import { FrankBackendTypes } from "frank-types";
import SentryClient, { SentryLoggingLevel } from "../sentryClient";

export const emitter = new EventEmitter2();

export enum AuthStates {
  NEW = "new",
  LOADING_EXISTING_SESSION = "loading_existing_session",
  LOADING_NEW_SESSION = "loading_new_session",
  LOGGED_IN = "logged_in",
  NOT_LOGGED_IN = "not_logged_in",
  ERROR = "error",
}

export interface AuthState {
  state: AuthStates;
  setState: (args: { state: AuthStates }) => void;
  onboardingState: FrankBackendTypes.OnboardingWorkflowState;
  setOnboardingState: (args: {
    onboardingState: FrankBackendTypes.OnboardingWorkflowState;
  }) => void;
}

const authContext = React.createContext<AuthState>(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = React.useState<AuthStates>(AuthStates.NEW);
  const [
    onboardingState,
    setOnboardingStatus,
  ] = React.useState<FrankBackendTypes.OnboardingWorkflowState>(null);

  const setState = React.useCallback(
    (args: { state: AuthStates }) => {
      SentryClient.addBreadcrumb({
        category: "auth",
        message: `Auth state changed from ${authState} to ${args.state}`,
        level: SentryLoggingLevel.Info,
      });
      setAuthState(args.state);
    },
    [setAuthState]
  );

  const setOnboardingState = React.useCallback(
    (args: { onboardingState: FrankBackendTypes.OnboardingWorkflowState }) => {
      setOnboardingStatus(args.onboardingState);
    },
    [setOnboardingStatus]
  );

  const validateSession = React.useCallback(async () => {
    SentryClient.addBreadcrumb({
      category: "auth",
      message: `validating session - setting auth state to ${AuthStates.LOGGED_IN}`,
      level: SentryLoggingLevel.Info,
    });
    setAuthState(AuthStates.LOADING_EXISTING_SESSION);
    const valid = await AuthN.hasValidSession();
    setAuthState(valid ? AuthStates.LOGGED_IN : AuthStates.NOT_LOGGED_IN);
    SentryClient.addBreadcrumb({
      category: "auth",
      message: `session validated - auth state set to ${authState}`,
      level: SentryLoggingLevel.Info,
    });
  }, [setAuthState]);

  React.useEffect(() => {
    validateSession();
  }, [validateSession]);

  React.useEffect(() => {
    window.addEventListener("online", AuthN.validateSession);
    return () => window.removeEventListener("online", AuthN.validateSession);
  }, []);

  React.useEffect(() => {
    if (authState === AuthStates.LOGGED_IN) {
      emitter.emit("auth-state-validated");
    }
  }, [authState]);

  return (
    <authContext.Provider
      value={{
        state: authState,
        setState,
        onboardingState,
        setOnboardingState,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export function useAuthState() {
  const {
    setState,
    state,
    onboardingState,
    setOnboardingState,
  } = React.useContext(authContext);
  return {
    authState: state,
    setAuthState: setState,
    onboardingState,
    setOnboardingState,
  };
}
