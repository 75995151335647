import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const MUTATION = gql`
  mutation recordEmailVerified($onboardingId: ID!) {
    recordEmailVerified(onboardingId: $onboardingId) {
      nothing
    }
  }
`;

export default function useRecordEmailVerified() {
  const [invokeRecordEmailVerified, { loading, error }] = useMutation<any, FrankBackendTypes.MutationRecordEmailVerifiedArgs>(MUTATION);
  const recordEmailVerified = React.useCallback(
    async ({ onboardingId }) => {
      await invokeRecordEmailVerified({
        variables: { onboardingId },
      });
    },
    [invokeRecordEmailVerified]
  );
  return { recordEmailVerified, loading, error };
}
