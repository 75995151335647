/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import * as React from "react";
import { useEffect } from "react";
import { useHistory, useLocation, matchPath, match } from "react-router";

function historyStackReducer(state: LocationHistory, action: Push | Pop) {
  if (action.type === "push") {
    if (state.stack.length > 50) {
      return {
        stack: [...state.stack.slice(-50), action.location],
        log: [...state.log.slice(-50), action.location],
      };
    }
    return {
      stack: [...state.stack, action.location],
      log: [...state.log, action.location],
    };
  }
  if (action.type === "pop") {
    return {
      stack: state.stack.slice(0, state.stack.length - 1),
      log: [...state.log, action.location],
    };
  }
  return state;
}

type LocationHistory = { stack: string[]; log: string[] };

type Push = {
  type: "push";
  location: string;
};

type Pop = {
  type: "pop";
  location: string;
};

const locationHistoryContext = React.createContext<LocationHistory>({
  log: [],
  stack: [],
});

export function LocationHistoryProvider({ children }) {
  const history = useHistory();
  const location = useLocation();

  const [locationHistory, dispatch] = React.useReducer<
    React.Reducer<LocationHistory, Push | Pop>
  >(
    historyStackReducer,
    window.localStorage.getItem("locationHistory")
      ? JSON.parse(window.localStorage.getItem("locationHistory"))
      : {
          stack: [location.pathname],
          log: [location.pathname],
        }
  );

  useEffect(() => {
    window.localStorage.setItem(
      "locationHistory",
      JSON.stringify(locationHistory)
    );
  }, [locationHistory]);

  React.useEffect(() => {
    const unregister = history.listen((newLocation, action) => {
      if (action === "POP") {
        dispatch({
          type: "pop",
          location: newLocation.pathname,
        });
      }
      if (action === "PUSH") {
        dispatch({
          type: "push",
          location: newLocation.pathname,
        });
      }
      if (action === "REPLACE") {
        dispatch({
          type: "push",
          location: newLocation.pathname,
        });
      }
    });

    return unregister;
  }, [history, dispatch]);
  return (
    <locationHistoryContext.Provider value={locationHistory}>
      {children}
    </locationHistoryContext.Provider>
  );
}

export function useLocationHistory() {
  const locationHistory = React.useContext(locationHistoryContext);

  const lastPage = React.useMemo(
    () => locationHistory.stack[locationHistory.stack.length - 2],
    [locationHistory]
  );

  const lastPageMatches: (path: string) => match = React.useCallback(
    (matchPattern: string): match => {
      return matchPath(lastPage, matchPattern);
    },
    [lastPage]
  );

  const anyPageMatches = React.useCallback(
    (matchPattern: string): match => {
      for (const location of locationHistory.log.reverse()) {
        const match = matchPath(location, matchPattern);
        if (match) {
          return match;
        }
      }
    },
    [locationHistory]
  );

  return {
    log: locationHistory.log,
    stack: locationHistory.stack,
    lastPage,
    lastPageMatches,
    anyPageMatches,
  };
}
