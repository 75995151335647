import { useQuery, gql } from "@apollo/client";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { FullCenter } from "@get-frank-eng/design-system";
import Loading from "../components/Loading";
import { FrankBackendTypes } from "frank-types";

const query = gql`
  query RedirectToCampaignOrTopic($channelId: ID!) {
    channel(channelId: $channelId) {
      id
      campaignId
      topicId
      topicSlug
    }
  }
`;

export default function RedirectToTopicOrCampaignFromChannelId() {
  const { channelId } = useParams<{ channelId: string }>();
  const { data, loading, error } = useQuery<
    Pick<FrankBackendTypes.Query, "channel">,
    { channelId: string }
  >(query, {
    variables: { channelId },
  });

  if (error) {
    throw error;
  }
  if (loading || !data) {
    return (
      <div className="h-screen">
        <FullCenter>
          <Loading />
        </FullCenter>
      </div>
    );
  }

  if (data?.channel.campaignId) {
    return <Redirect to={`/campaigns/${data.channel.campaignId}`} />;
  }

  if (data?.channel.topicId) {
    return <Redirect to={`/topics/${data.channel.topicSlug}`} />;
  }
}
