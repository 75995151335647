import * as React from "react";
import classNames from "classnames";
import { ScreenSizes, useResponsive } from "@get-frank-eng/design-system";

export const StepLabel = ({ children }) => {
  const { screenSize } = useResponsive();
  return (
    <div
      className={classNames([
        "text-canvas-400",
        {
          "t-label-2": screenSize >= ScreenSizes.SM,
          "t-label-3": screenSize < ScreenSizes.SM,
        },
      ])}
    >
      {children}
    </div>
  );
};

export const SectionHeader = ({ children }) => {
  return <div className={classNames(["plus t-title-4"])}>{children}</div>;
};
