export const getMentionHref = (mention) => {
  switch (mention.type) {
    case "coworker":
      return `/coworkerProfiles/${mention.id}`;
    case "channel":
      return `/channel/${mention.id}`;
    default:
      throw new Error(`mention type is not recognized ${mention.type}`);
  }
};
