import * as React from "react";
import { Avatar, ProfileHeader } from "@get-frank-eng/design-system";

const WrappedPageHeader: React.FC<{
  avatarProps?: Omit<React.ComponentProps<typeof Avatar>, "size">;
  title: string;
  right?: React.ReactNode;
  subtitle?: string;
}> = ({ avatarProps, title, right, subtitle }) => {
  return (
    <div className="mt-16">
      <ProfileHeader
        avatarProps={avatarProps}
        title={title}
        right={right}
        subtitle={subtitle}
      />
    </div>
  );
};

export default WrappedPageHeader;
