import * as React from "react";

type DraftContext = {
  setDraft(channelId: string, draft: string): void;
  drafts: {
    [channelId: string]: string;
  };
  // getDraft(channelId: string): void;
};

const context = React.createContext<DraftContext>({
  setDraft(cid, d) {},
  drafts: {},
  // getDraft(cid) {},
});

export const useDrafts = () => {
  const { drafts, setDraft } = React.useContext(context);
  return { drafts, setDraft };
};

export const DraftProvider: React.FC = ({ children }) => {
  const [drafts, setState] = React.useState<{ [channelId: string]: string }>(
    {}
  );
  const setDraft = React.useCallback(
    (channelId: string, draft: string) => {
      setState({ ...drafts, [channelId]: draft });
    },
    [drafts, setState]
  );
  // const getDraft = React.useCallback((channelId: string) => {}, []);
  return (
    <context.Provider value={{ setDraft, drafts }}>{children}</context.Provider>
  );
};
