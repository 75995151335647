import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query Campaign($campaignId: ID!) {
    myProfile {
      id
      shortName
      firstName
    }
    campaign(campaignId: $campaignId) {
      id
      title
      workflowState
      signaturesVisible
      signatureGoal
      signatureCount
      categories
      relationshipToMe
      updatedAtTimeAgo
      publishingValidationIssues {
        category
        message
      }
      links {
        id
        preview {
          title
          subtitle
          imageUrl
          url
        }
      }
      petitionRecipients {
        fullName
        email
        id
      }
      contentJSON
      comments {
        id
        timeAgo
        documentId
        comment
        profilePicUrl
        commenterName
        authorType
        userId
        isReplyable
        replies {
          id
          timeAgo
          comment
          profilePicUrl
          commenterName
          userId
        }
      }
    }
  }
`;

export default function useCampaignBuilderData({
  campaignId,
}: {
  campaignId: string;
}) {
  const { data, loading, error, refetch, called } = useQuery<{
    campaign: FrankBackendTypes.Campaign;
    myProfile: FrankBackendTypes.MyProfile;
  }>(QUERY, {
    variables: { campaignId },
  });

  const [isFakeLoading, setFakeLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setFakeLoading(false), 1000);
  }, [setFakeLoading]);

  return {
    calledCampaign: called,
    campaign: data?.campaign,
    myProfile: data?.myProfile,
    loadingCampaign: !!data?.campaign && (loading || isFakeLoading),
    errorCampaign: error,
    refetchCampaign: refetch,
  };
}
