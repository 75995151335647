import { useQuery, gql } from "@apollo/client";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { FullCenter } from "@get-frank-eng/design-system";
import Loading from "../components/Loading";
import { FrankBackendTypes } from "frank-types";

const query = gql`
  query Redirect($coworkerId: ID!) {
    coworker(coworkerId: $coworkerId) {
      id
      user {
        id
      }
    }
  }
`;

export default function RedirectToProfileFromCoworkerId() {
  const { coworkerId } = useParams<{ coworkerId: string }>();
  const { data, loading, error } = useQuery<
    Pick<FrankBackendTypes.Query, "coworker">,
    { coworkerId: string }
  >(query, {
    variables: { coworkerId },
  });

  if (error) {
    throw error;
  }
  if (loading || !data) {
    return (
      <div className="h-screen">
        <FullCenter>
          <Loading />
        </FullCenter>
      </div>
    );
  }

  if (data?.coworker && !data?.coworker.user) {
    return <Redirect to={`/coworkers`} />;
  }

  if (data) {
    return <Redirect to={`/users/${data?.coworker.user?.id}`} />;
  }
}
