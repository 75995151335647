import { FrankBackendTypes } from "frank-types";
import { GraphQLError } from "graphql";
import * as React from "react";
import { AuthStates, useAuthState } from "../../Auth/AuthState";
import { fetchNavigation } from "../dataAccess/queries/navigation";

type KeyMap = { [otherId: string]: string };
type NavigationStateContext = {
  ids: KeyMap;
  navigation: FrankBackendTypes.Navigation;
  loading: boolean;
  graphQLErrors: GraphQLError[];
  networkError: Error;
  refetch: () => void;
};

const defaultContext: NavigationStateContext = {
  ids: {},
  navigation: null,
  loading: false,
  graphQLErrors: [],
  networkError: null,
  refetch() {},
};

const context = React.createContext<NavigationStateContext>(defaultContext);

export function useChannelIds(otherId: string) {
  const { ids } = React.useContext(context);
  return ids[otherId];
}

interface NavState<T extends Error = Error> {
  navigation: FrankBackendTypes.Navigation;
  loading: boolean;
  graphQLErrors: GraphQLError[];
  networkError: T;
}

type Action =
  | { type: "loading" }
  | { type: "loaded"; data: FrankBackendTypes.Navigation }
  | { type: "graphql-error"; graphQLErrors: readonly GraphQLError[] }
  | { type: "network-error"; networkError: Error };

const defaultState: NavState = {
  loading: false,
  graphQLErrors: [],
  navigation: null,
  networkError: null,
};

function navReducer(prev: NavState, action: Action): NavState {
  switch (action.type) {
    case "loading":
      return { ...prev, loading: true };
    case "graphql-error":
      return {
        ...prev,
        loading: false,
        graphQLErrors: [...action.graphQLErrors],
      };
    case "loaded":
      return {
        ...prev,
        loading: false,
        networkError: null,
        navigation: action.data,
      };
    case "network-error":
      return {
        ...prev,
        loading: false,
        networkError: action.networkError,
      };
    default:
      return prev;
  }
}

export const NavigationStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(navReducer, defaultState);
  const { authState, onboardingState } = useAuthState();

  const getNav = React.useCallback(async () => {
    if (
      authState === AuthStates.LOGGED_IN &&
      onboardingState === FrankBackendTypes.OnboardingWorkflowState.Finished
    ) {
      try {
        dispatch({ type: "loading" });
        const result = await fetchNavigation();
        if (result.errors) {
          dispatch({ type: "graphql-error", graphQLErrors: result.errors });
        }
        if (result.navigation) {
          dispatch({ type: "loaded", data: result.navigation });
        }
      } catch (e) {
        dispatch({ type: "network-error", networkError: e });
        throw e;
      }
    }
  }, [authState, dispatch, onboardingState]);

  React.useEffect(() => {
    getNav();
  }, [getNav, authState]);

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      getNav();
    }, 1000 * 60 * 5);

    return () => window.clearInterval(interval);
  }, [getNav]);

  const ids = React.useMemo(() => {
    if (state.navigation) {
      return state.navigation.sections.reduce((acc, current) => {
        for (const item of current.items) {
          if (item.channelId) {
            acc[item.identifier] = item.channelId;
          }
        }
        return acc;
      }, {});
    }
    return {};
  }, [state.navigation]);

  return (
    <context.Provider
      value={{
        ids,
        navigation: state.navigation,
        loading: state.loading,
        graphQLErrors: state.graphQLErrors,
        networkError: state.networkError,
        refetch: getNav,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useNavigationState = () => {
  const {
    ids,
    navigation,
    loading,
    networkError,
    graphQLErrors,
    refetch,
  } = React.useContext(context);
  return {
    ids,
    navigation,
    loading,
    networkError,
    graphQLErrors,
    refetch,
  };
};
