import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const COMPLETE_TOUR_MUTATION = gql`
  mutation CompleteTour($tour: TourType!) {
    completeTour(tour: $tour) {
      nothing
    }
  }
`;

export default function useCompleteTour() {
  const [invokeCompleteTour] = useMutation<
    Pick<FrankBackendTypes.Mutation, "completeTour">,
    FrankBackendTypes.MutationCompleteTourArgs
  >(COMPLETE_TOUR_MUTATION);

  const completeTour = React.useCallback(
    (tour) => {
      return invokeCompleteTour({
        variables: {
          tour,
        },
      });
    },
    [invokeCompleteTour]
  );

  return { completeTour };
}
