/* eslint-disable class-methods-use-this */
import * as KeratinAuthN from "keratin-authn";
import client from "../gqlClient";
import axios from "axios";
import * as analytics from "../analytics";
import jwt from "jsonwebtoken";
import SentryClient, { SentryLoggingLevel } from "../sentryClient";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
class AuthN {
  constructor() {
    this.configure();
  }

  public configure() {
    KeratinAuthN.setHost(process.env.REACT_APP_AUTHN_URL);
    KeratinAuthN.setLocalStorageStore("authn");
  }

  async forgotPassword(email: string) {
    await KeratinAuthN.requestPasswordReset(email.toLowerCase());
  }

  async submitNewPasswordAfterReset(token: string, newPassword: string) {
    await KeratinAuthN.resetPassword({ password: newPassword, token });
  }

  login = async (email: string, password: string) => {
    await KeratinAuthN.login({ username: email.toLowerCase(), password });
  };

  logout = async () => {
    await KeratinAuthN.logout();
    await client.resetStore();
    localStorage.clear();
    analytics.logout();
  };

  async passwordStrength(password: string): Promise<number> {
    const { data } = await axios.post<{
      result: { score: number; requiredScore: number };
    }>(
      `${process.env.REACT_APP_AUTHN_URL}/password/score`,
      `password=${encodeURIComponent(password)}`,
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );
    return data.result.score;
  }

  async validateSession() {
    await KeratinAuthN.restoreSession();
  }

  async hasValidSession() {
    try {
      await this.validateSession();
      return true;
    } catch (e) {
      console.error("no session");
      return false;
    }
  }

  async validateSessionAgressive() {
    await KeratinAuthN.importSession();
  }

  getToken() {
    return KeratinAuthN.session();
  }

  async submitPasswordlessLogin(token: string) {
    const { data } = await axios.post<{
      result: { id_token: string };
    }>(`${process.env.REACT_APP_AUTHN_URL}/session/token`, `token=${token}`, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    localStorage.setItem("authn", data.result.id_token);
  }

  private async hasTokenChanged(initial: string, times: number = 0) {
    SentryClient.addBreadcrumb({
      category: "auth",
      message: `checking to see if token has changed for the ${times} time`,
      level: SentryLoggingLevel.Info,
    });
    if (times > 50) {
      throw new Error("timeout");
    }
    if (initial !== localStorage.getItem("authn")) {
      return;
    }
    await sleep(250);
    return this.hasTokenChanged(initial, times + 1);
  }

  async waitForValidSession(): Promise<void> {
    SentryClient.addBreadcrumb({
      category: "auth",
      message: "waiting for valid session",
      level: SentryLoggingLevel.Info,
    });
    const token = localStorage.getItem("authn");
    if (!token) {
      SentryClient.addBreadcrumb({
        category: "auth",
        message: "no token - session is not valid",
        level: SentryLoggingLevel.Info,
      });
      return;
    }
    const decoded = jwt.decode(token);
    const expirationSeconds = decoded["exp"];

    if (Date.now() < expirationSeconds * 1000) {
      return;
    }
    return this.hasTokenChanged(token);
  }
}

const authnSingleton = new AuthN();

// eslint-disable-next-line dot-notation
window["authnSingleton"] = authnSingleton;

export default authnSingleton;
