import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const ARCHIVE = gql`
  mutation ArchiveCoworkers($coworkerIds: [ID!]!) {
    archiveCoworkers(coworkerIds: $coworkerIds) {
      nothing
    }
  }
`;

const UNARCHIVE = gql`
  mutation UnArchiveCoworkers($coworkerIds: [ID!]!) {
    unArchiveCoworkers(coworkerIds: $coworkerIds) {
      nothing
    }
  }
`;

export default function useArchiveMutations() {
  const [invokeArchive, { loading: loadingArchive }] = useMutation<
    any,
    FrankBackendTypes.MutationArchiveCoworkersArgs
  >(ARCHIVE);

  const [invokeUnArchive, { loading: loadingUnArchive }] = useMutation<
    any,
    FrankBackendTypes.MutationUnArchiveCoworkersArgs
  >(UNARCHIVE);

  return { invokeArchive, invokeUnArchive, loadingArchive, loadingUnArchive };
}
