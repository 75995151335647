let resolve = (config: Configuration) => {};
export interface Configuration {
  getAuthorizationHeader: () => string;
  apiUrl: string;
  websocketUrl: string;
  waitForValidSession: () => Promise<void>;
}

const configPromise = new Promise<Configuration>(function (_resolve, reject) {
  resolve = _resolve;
});

export const configure = (config: Configuration) => {
  resolve(config);
};

export function getConfig(): Promise<Configuration> {
  return configPromise;
}
