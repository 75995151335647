import axios from "axios";
import generateAuthHeader from "./Auth/generateAuthHeader";
import authN from "./Auth/AuthN";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

http.interceptors.request.use(
  function (config) {
    const header = generateAuthHeader();
    if (header) {
      config.headers.Authorization = header;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.request.use(async (config) => {
  await authN.waitForValidSession();
  return config;
});

export default http;
