import { useMutation, gql } from "@apollo/client";
import * as React from "react";

const MUTATION = gql`
  mutation PublishCampaign($campaignId: ID!) {
    publishCampaign(campaignId: $campaignId) {
      id
      workflowState
    }
  }
`;

export default function usePublish({ campaignId }: { campaignId: string }) {
  const [invokePublish, { loading, error }] = useMutation(MUTATION);

  const publish = React.useCallback(() => {
    return invokePublish({ variables: { campaignId } });
  }, [campaignId, invokePublish]);

  return {
    publish,
    loadingPublish: loading,
    errorPublish: error
  };
}
