import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const COWORKER_TRUST_VOTE = gql`
  mutation CoworkerTrustVote($input: CoworkerTrustVoteDTO!) {
    coworkerTrustVote(input: $input) {
      id
      totalTrustUpvotes
      totalTrustDownvotes
      yourTrustLevel
      netTrust
      trustJudgementCount
      trustedBy {
        id
      }
      distrustedBy {
        id
      }
    }
  }
`;

const REMOVE_COWORKER_TRUST_VOTE = gql`
  mutation RemoveCoworkerTrustVote($coworkerId: ID!) {
    removeCoworkerTrustVote(coworkerId: $coworkerId) {
      id
      totalTrustUpvotes
      totalTrustDownvotes
      yourTrustLevel
      netTrust
      trustJudgementCount
      trustedBy {
        id
      }
      distrustedBy {
        id
      }
    }
  }
`;

export default function useCoworkerTrust() {
  const [
    invokeCoworkerTrustVote,
    { loading: loadingCoworkerTrustVote },
  ] = useMutation<
    { coworkerTrustVote: FrankBackendTypes.Coworker },
    FrankBackendTypes.MutationCoworkerTrustVoteArgs
  >(COWORKER_TRUST_VOTE);

  const [
    invokeRemoveCoworkerTrustVote,
    { loading: loadingRemoveCoworkerTrustVote },
  ] = useMutation<
    { coworkerTrustVote: FrankBackendTypes.Coworker },
    FrankBackendTypes.MutationRemoveCoworkerTrustVoteArgs
  >(REMOVE_COWORKER_TRUST_VOTE);

  const removeCoworkerTrustVote = React.useCallback(
    async ({
      coworkerId,
    }: {
      coworkerId: FrankBackendTypes.Coworker["id"];
    }) => {
      await invokeRemoveCoworkerTrustVote({
        variables: {
          coworkerId,
        },
      });
    },
    [invokeRemoveCoworkerTrustVote]
  );

  const coworkerTrustVote = React.useCallback(
    async ({
      coworkerId,
      trust,
      rejectionReason,
      rejectionDetail,
    }: {
      coworkerId: FrankBackendTypes.Coworker["id"];
      trust: number;
      rejectionReason?: FrankBackendTypes.CoworkerRejectionReason;
      rejectionDetail?: string;
    }) => {
      await invokeCoworkerTrustVote({
        variables: {
          input: {
            coworkerId,
            trust,
            rejectionReason,
            rejectionDetail,
          },
        },
      });
    },
    [invokeCoworkerTrustVote]
  );

  return {
    coworkerTrustVote,
    loadingCoworkerTrustVote,
    removeCoworkerTrustVote,
    loadingRemoveCoworkerTrustVote,
  };
}
