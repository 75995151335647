import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query templates($categoryId: String) {
    templates(categoryId: $categoryId) {
      id
      title
      description
      categories {
        id
        name
      }
      author {
        id
        name
        bio
        image {
          url
        }
      }
    }
  }
`;

export default function useGetTemplates(categoryId: string) {
  const { loading, error, data } = useQuery<{
    templates: FrankBackendTypes.Template[];
  }>(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      categoryId,
    },
  });

  return {
    templates: data?.templates,
    loadingTemplates: loading,
    errorTemplates: error,
  };
}
