import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MUTATION = gql`
  mutation VerifyEmail($input: SendVerifyEmailInput!) {
    sendVerifyEmail(input: $input) {
      nothing
    }
  }
`;

export default function useSendVerifyEmail() {
  const [invokeSendVerifyEmail, { loading, error }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "sendVerifyEmail">,
    FrankBackendTypes.MutationSendVerifyEmailArgs
  >(MUTATION);
  const sendVerifyEmail = React.useCallback(
    async (email: string, groupId: string) => {
      await invokeSendVerifyEmail({ variables: { input: { email, groupId } } });
    },
    [invokeSendVerifyEmail]
  );

  return { sendVerifyEmail, loading, error };
}
