/* eslint-disable dot-notation */
import * as React from "react";

export default function useIntercom(intercomProps: {
  userId: string;
  intercomHash: string;
  groupId: string;
  groupName: string;
  email: string;
}) {
  const [isIntercomStarted, setIntercomStarted] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    if (isIntercomStarted) {
      return;
    }
    if (!intercomProps.intercomHash) {
      return;
    }
    window["intercomSettings"] = {
      hide_default_launcher: true,
      custom_launcher_selector:
        "#intercom-launcher, #second-intercom-launcher, .intercom-launcher",
    };

    const intercomConfig = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: intercomProps.userId,
      user_hash: intercomProps.intercomHash,
      company: {
        company_id: intercomProps.groupId,
        name: intercomProps.groupName,
      },
      email: intercomProps.email,
    };

    window["Intercom"]("boot", intercomConfig);
  }, [intercomProps, setIntercomStarted, isIntercomStarted]);
}
