import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { MutationRequestInviteArgs } from "../../../../../frank-types/dist/frank-backend-types";

const MUTATION = gql`
  mutation MissingCompany($input: MissingCompanyInput!) {
    missingCompany(input: $input) {
      nothing
    }
  }
`;

export default function useMissingCompany() {
  const [invokeMissingCompany, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "missingCompany">,
    FrankBackendTypes.MutationMissingCompanyArgs
  >(MUTATION);
  const missingCompany = React.useCallback(
    async (input: FrankBackendTypes.MutationMissingCompanyArgs["input"]) => {
      await invokeMissingCompany({ variables: { input } });
    },
    [invokeMissingCompany]
  );

  return { missingCompany, loading, error, called };
}
