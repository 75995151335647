import * as React from "react";
import Frank from "../components/icons/Frank";

export const MinimalFormLayout: React.FC = ({ children }) => {
  return (
    <div className="w-screen h-screen overflow-y-auto pb-32 sm:pb-12">
      <div className="mx-2 sm:mx-auto max-w-xl mt-12 sm:mt-24 space-y-8">
        <a
          href="https://getfrank.com"
          className="hidden sm:block absolute top-0 left-0 mt-4 ml-6"
        >
          <Frank />
        </a>
        {children}
      </div>
    </div>
  );
};
