import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { generateFutureDate } from "../../utils/Date";
import { InviteForm } from "../CreateLinkInvite";
import UpdateExpiration from "./UpdateExpiration";
import UpdatePassphrase from "./UpdatePassphrase";
import UpdateSeats from "./UpdateSeats";

const UPDATE_INVITE_LINK_MAX_SEATS = gql`
  mutation UpdateInviteLinkMaxSeats($input: UpdateInviteLinkMaxSeatsDTO!) {
    updateInviteLinkMaxSeats(input: $input) {
      nothing
    }
  }
`;

const UPDATE_INVITE_LINK_PASSPHRASE = gql`
  mutation UpdateInviteLinkPassphrase($input: UpdateInviteLinkPassphraseDTO!) {
    updateInviteLinkPassphrase(input: $input) {
      nothing
    }
  }
`;

const UPDATE_INVITE_LINK_EXPIRATION = gql`
  mutation UpdateInviteLinkExpiration($input: UpdateInviteLinkExpirationDTO!) {
    updateInviteLinkExpiration(input: $input) {
      nothing
    }
  }
`;

export const findExpiration = (currentExpiration: Date, extension: number) => {
  if (currentExpiration) {
    return generateFutureDate(new Date(currentExpiration), extension);
  }
  return generateFutureDate(new Date(), extension);
};

const UpdateInvite = ({
  invite,
  idModifier,
  refetch,
}: {
  invite: FrankBackendTypes.Invite;
  idModifier?: string;
  refetch?: () => any;
}) => {
  const [
    invokeUpdateInviteLinkMaxSeats,
    { loading: updateMaxSeatsLoading },
  ] = useMutation<
    { updateInviteLink: FrankBackendTypes.Invite },
    FrankBackendTypes.MutationUpdateInviteLinkMaxSeatsArgs
  >(UPDATE_INVITE_LINK_MAX_SEATS);

  const callMutationMaxSeats = React.useCallback(
    async (data: Partial<InviteForm>, token: string) => {
      const input: FrankBackendTypes.UpdateInviteLinkMaxSeatsDto = {
        token,
        maxSeats: +data.maxSeats ? +data.maxSeats : null,
      };
      const { data: mutationData } = await invokeUpdateInviteLinkMaxSeats({
        variables: { input },
      });
      if (refetch) {
        await refetch();
      }
    },
    [invokeUpdateInviteLinkMaxSeats, refetch]
  );

  const [
    invokeUpdateInviteLinkPassphrase,
    { loading: updatePassphraseLoading },
  ] = useMutation<
    { updateInviteLink: FrankBackendTypes.Invite },
    FrankBackendTypes.MutationUpdateInviteLinkPassphraseArgs
  >(UPDATE_INVITE_LINK_PASSPHRASE);

  const callMutationPassphrase = React.useCallback(
    async (data: Partial<InviteForm>, token: string) => {
      const input: FrankBackendTypes.UpdateInviteLinkPassphraseDto = {
        token,
        passphrase: data.passphrase ? data.passphrase : null,
      };

      const { data: mutationData } = await invokeUpdateInviteLinkPassphrase({
        variables: { input },
      });
      if (refetch) {
        await refetch();
      }
    },
    [invokeUpdateInviteLinkPassphrase, refetch]
  );

  const [
    invokeUpdateInviteLinkExpiration,
    { loading: updateExpirationLoading },
  ] = useMutation<
    { updateInviteLink: FrankBackendTypes.Invite },
    FrankBackendTypes.MutationUpdateInviteLinkExpirationArgs
  >(UPDATE_INVITE_LINK_EXPIRATION);

  const callMutationExpiration = React.useCallback(
    async (data: Partial<InviteForm>, token: string) => {
      const input: FrankBackendTypes.UpdateInviteLinkExpirationDto = {
        token,
        expiration: data.expiration
          ? findExpiration(new Date(), +data.expiration)
          : null,
      };

      const { data: mutationData } = await invokeUpdateInviteLinkExpiration({
        variables: { input },
      });
      if (refetch) {
        await refetch();
      }
    },
    [invokeUpdateInviteLinkExpiration, refetch]
  );

  return (
    <div className="mt-6">
      <UpdateSeats
        idModifier={idModifier}
        maxSeats={invite.maxSeats}
        update={callMutationMaxSeats}
        token={invite.token}
        loading={updateMaxSeatsLoading}
      />
      <UpdatePassphrase
        idModifier={idModifier}
        currentPassphrase={invite.passphrase}
        update={callMutationPassphrase}
        token={invite.token}
        loading={updatePassphraseLoading}
      />

      <UpdateExpiration
        idModifier={idModifier}
        currentExpiration={invite.expiresIn}
        update={callMutationExpiration}
        token={invite.token}
        loading={updateExpirationLoading}
      />
    </div>
  );
};

export default UpdateInvite;
