import { gql } from "@apollo/client";
import { getClient } from "../../gql-client";
import { ChatTypes } from "frank-types";

export async function createChannel(
  input: ChatTypes.CreateChannelInput
): Promise<ChatTypes.Channel> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "createChannel">,
    ChatTypes.MutationCreateChannelArgs
  >({
    mutation: gql`
      mutation CreateChannel($input: CreateChannelInput!) {
        createChannel(input: $input) {
          id
        }
      }
    `,
    variables: { input },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.createChannel;
}

export async function setViewCursor(channelId: string): Promise<void> {
  const client = await getClient();
  const { data, errors } = await client.mutate<
    Pick<ChatTypes.Mutation, "setViewCursor">,
    ChatTypes.MutationSetViewCursorArgs
  >({
    mutation: gql`
      mutation SetViewCursor($channelId: ID!) {
        setViewCursor(channelId: $channelId) {
          nothing
        }
      }
    `,
    variables: { channelId },
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }
}
