import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation ViewCoworkerActivity($coworkerId: ID!) {
    viewCoworkerActivity(coworkerId: $coworkerId) {
      id
      notifications
    }
  }
`;

export default function useViewCoworkerActivity({
  coworkerId,
}: {
  coworkerId: string;
}) {
  const [invokeViewCoworkerActivity, { loading, error }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "viewCoworkerActivity">,
    FrankBackendTypes.MutationViewCoworkerActivityArgs
  >(mutation, { variables: { coworkerId } });

  return {
    viewCoworkerActivity: invokeViewCoworkerActivity,
    loading,
    error,
  };
}
