import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation CreateAttachmentForChannel(
    $attachmentId: ID!
    $topicId: ID
    $campaignId: ID
  ) {
    createChannelAssnForAttachment(
      attachmentId: $attachmentId
      topicId: $topicId
      campaignId: $campaignId
    ) {
      nothing
    }
  }
`;

export default function useCreateAttachmentForChannel({
  topicId,
  campaignId,
}: {
  topicId?: string;
  campaignId?: string;
}) {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "createChannelAssnForAttachment">,
    FrankBackendTypes.MutationCreateChannelAssnForAttachmentArgs
  >(mutation);

  const createAttachmentForChannel = React.useCallback(
    async (attachmentId) => {
      const res = await invokeMutation({
        variables: { attachmentId, topicId, campaignId },
      });
      return res.data?.createChannelAssnForAttachment;
    },
    [campaignId, invokeMutation, topicId]
  );

  return {
    createAttachmentForChannel,
    loading,
    error,
    called,
  };
}
