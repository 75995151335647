import * as React from "react";
import useSession from "../../../Auth/useSession";
import AuthN from "../../../Auth/AuthN";

export default function useSetPassword({ groupId }: { groupId: string }) {
  const { login } = useSession({ groupId });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const setPassword = React.useCallback(
    async (token: string, email: string, password: string) => {
      try {
        setLoading(true);
        await AuthN.submitNewPasswordAfterReset(token, password);
        await login(email, password);
        // verify password changed
      } catch (e) {
        setError(e);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [login, setLoading, setError]
  );
  return { setPassword, loading, error };
}
