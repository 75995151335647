import axios from "axios";

export default async function getGroupForJwt(jwt: string) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/auth/groupIdsForUser`,
    {
      params: {
        jwt,
      },
    }
  );
  const firstGroup = data[0];
  if (!firstGroup) {
    throw new Error("user not part of groups");
  }
  return firstGroup;
}
