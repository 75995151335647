import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const SIGN = gql`
  mutation Sign($input: SignDTO!) {
    signCampaign(input: $input) {
      nothing
    }
  }
`;

const UNSIGN = gql`
  mutation Unsign($campaignId: ID!) {
    unsignCampaign(campaignId: $campaignId) {
      nothing
    }
  }
`;

export default function useSignCampaign({
  campaignId,
  afterSign,
}: {
  campaignId: string;
  afterSign?: () => void;
}) {
  const [invokeSign, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationSignCampaignArgs
  >(SIGN);

  const [
    invokeUnsign,
    { loading: loadingUnsign, called: calledUnSign, error: errorUnsign },
  ] = useMutation<any, FrankBackendTypes.MutationUnsignCampaignArgs>(UNSIGN);

  const sign = React.useCallback(
    async ({ statement }: { statement?: string }) => {
      await invokeSign({ variables: { input: { campaignId, statement } } });
      if (afterSign) {
        afterSign();
      }
    },
    [invokeSign, campaignId, afterSign]
  );

  const unsign = React.useCallback(async () => {
    await invokeUnsign({ variables: { campaignId } });
    if (afterSign) {
      afterSign();
    }
  }, [invokeUnsign, campaignId, afterSign]);
  return {
    sign,
    unsign,
    loading,
    called,
    error,
    loadingUnsign,
    calledUnSign,
    errorUnsign,
  };
}
