import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const getDateAcceptedNoMgmt = gql`
  query Profile {
    myProfile {
      id
      membership {
        id
        dateAcceptedNoMgmt
      }
    }
  }
`;

const useMyProfileDateAcceptedNoMgmt = () => {
  const { data, error, loading, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "myProfile">
  >(getDateAcceptedNoMgmt);

  return {
    hasAcceptedShareWarning: !!data?.myProfile.membership.dateAcceptedNoMgmt,
    error,
    loading,
    refetch,
  };
};

export default useMyProfileDateAcceptedNoMgmt;
