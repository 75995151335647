import { gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import once from "lodash/once";
import * as React from "react";
import { Redirect, useLocation } from "react-router";
import { AuthStates } from "../Auth/AuthState";
import useSession from "../Auth/useSession";
import client from "../gqlClient";

const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($input: VerifyEmailDTO!) {
    verifyEmail(input: $input) {
      id
      workflowState
    }
  }
`;

/**
 * there was a narly react bug where this was running twice
 * since this is such a stand-alone feature, this hacky fix
 * was deemed accceptable. We use locash to make sure this runs
 * only once
 */
const invokeVerifyEmail = once(async function invokeVerifyEmail(
  token: string
): Promise<FrankBackendTypes.Onboarding> {
  const { data } = await client.mutate<
    Pick<FrankBackendTypes.Mutation, "verifyEmail">,
    FrankBackendTypes.MutationVerifyEmailArgs
  >({
    mutation: VERIFY_EMAIL_MUTATION,
    variables: {
      input: { token },
    },
  });
  return data.verifyEmail;
});

const VerifyEmail = () => {
  const [verificationHasRun, setVerificationHasRun] = React.useState(false);
  const { search } = useLocation();
  const token = React.useMemo(() => new URLSearchParams(search).get("token"), [
    search,
  ]);
  const authnToken = React.useMemo(
    () => new URLSearchParams(search).get("authN"),
    [search]
  );

  const [error, setError] = React.useState(null);

  const [
    onboardingRecord,
    setOnboardingRecord,
  ] = React.useState<FrankBackendTypes.Onboarding>(null);

  const { loginWithOneTimeToken, authState } = useSession({});

  const verify = React.useCallback(async () => {
    try {
      const onboarding = await invokeVerifyEmail(token);
      setOnboardingRecord(onboarding);
    } catch (e) {
      setError(e);
    }
  }, [token, setOnboardingRecord, setError]);

  const oneTimeToken = React.useCallback(async () => {
    if (authState !== AuthStates.NOT_LOGGED_IN) {
      return;
    }
    if (authnToken) {
      await loginWithOneTimeToken(authnToken);
    } else {
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login?after=/verifyEmail?token=${token}`;
    }
  }, [authState, authnToken, loginWithOneTimeToken, token]);

  React.useEffect(() => {
    if (verificationHasRun) {
      return;
    }
    if (authState === AuthStates.LOGGED_IN) {
      verify();
      setVerificationHasRun(true);
    }
  }, [authState, verificationHasRun, verify]);

  React.useEffect(() => {
    oneTimeToken();
  }, [oneTimeToken]);

  if (!onboardingRecord) {
    return null;
  }

  if (
    onboardingRecord?.workflowState ===
    FrankBackendTypes.OnboardingWorkflowState.Finished
  ) {
    return <Redirect to="/" />;
  }

  if (
    onboardingRecord?.workflowState ===
      FrankBackendTypes.OnboardingWorkflowState.SecurityVerification ||
    onboardingRecord?.workflowState ===
      FrankBackendTypes.OnboardingWorkflowState.InGroupReview
  ) {
    return <Redirect to="/finish-onboarding" />;
  }

  if (error) {
    throw new Error(error.message);
  }

  return null;
};

export default VerifyEmail;
