import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query CampaignCollaborator($campaignId: ID!) {
    collaboratorInvite(campaignId: $campaignId) {
      id
      inviter {
        id
        shortName
        profilePic {
          id
          url
        }
      }
      campaign {
        id
        title
        workflowState
        relationshipToMe
        collaboratorWorkflowState
      }
    }
  }
`;

export default function useCollaboratorInviteQuery({
  campaignId,
}: {
  campaignId: string;
}) {
  const { data, loading, error, refetch } = useQuery<{
    collaboratorInvite: FrankBackendTypes.Collaborator;
  }>(QUERY, {
    variables: { campaignId },
  });

  return {
    collaboratorInvite: data?.collaboratorInvite,
    loadingCollaboratorInvite: loading,
    errorCollaboratorInvite: error,
    refetchCollaboratorInvite: refetch,
  };
}
