import { configure as configureChat } from "@get-frank-eng/chat-client";
import generateAuthHeader from "../../Auth/generateAuthHeader";
import { emitter } from "../../Auth/AuthState";
import AuthN from "../../Auth/AuthN";
import SentryClient, { SentryLoggingLevel } from "../../sentryClient";

const configureChatBackend = () => {
  emitter.on("auth-state-validated", () => {
    SentryClient.addBreadcrumb({
      category: "chat",
      message: `received auth-state-validated event, going to configure chat if I find a token in local storage`,
      level: SentryLoggingLevel.Info,
    });

    const token = localStorage.getItem("authn");
    if (token) {
      SentryClient.addBreadcrumb({
        category: "chat",
        message: "configuring chat",
        level: SentryLoggingLevel.Info,
      });
      configureChat({
        apiUrl: `${process.env.REACT_APP_CHAT_API_URL}/graphql`,
        websocketUrl: process.env.REACT_APP_CHAT_API_URL,
        getAuthorizationHeader: generateAuthHeader,
        waitForValidSession: () => AuthN.waitForValidSession(),
      });
    }
  });
};

export default configureChatBackend;
