import { ChatTypes } from "frank-types";
import { gql } from "@apollo/client";
import { getClient } from "../gql-client";

export async function getCurrentChatUser(): Promise<{
  whoami: ChatTypes.User;
  whoamiAnonymous: ChatTypes.User;
}> {
  const client = await getClient();
  const { data, errors, loading } = await client.query<
    Pick<ChatTypes.Query, "whoami" | "whoamiAnonymous">
  >({
    query: gql`
      query {
        whoami {
          id
          displayName
          profilePic
        }
        whoamiAnonymous {
          id
          displayName
          profilePic
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data;
}
