import { useQuery, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query FriendlyFaces {
    navigation {
      friendlyFaces {
        id
        url
      }
    }
  }
`;

export default function useFindFriendlyFaces() {
  const { data, loading, error } = useQuery<{
    navigation: FrankBackendTypes.Navigation;
  }>(QUERY);

  return {
    friendlyFaces: data?.navigation.friendlyFaces,
    loadingFriendlyFaces: loading,
    errorFriendlyFaces: error,
  };
}
